import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";

import Header from "../../components/UI/Header/Header";
import Loader from "../../components/UI/Loader/Loader";
import FormSlide from "./FormSlide/FormSlide";
import QuestionSlide from "./QuestionSlide/QuestionSlide";
import ErrorHandler from "../../components/UI/ErrorHandler/ErrorHandler";

import bg_section0 from "../../assets/Onsagers_IP-sjekk_Arkitektur_2560x1440px.jpg";
import bg_section1 from "../../assets/ONSAGER_red_BG_2560_2560_2.jpg";
import bg_section2 from "../../assets/ONSAGER_green_BG_2560_2560_1.jpg";
import bg_section3 from "../../assets/ONSAGER_blue_BG_2560_2560_3.jpg";

import { Grid, makeStyles } from "@material-ui/core";
import ResultSlide from "./ResultSlide/ResultSlide";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "1920px",
    margin: "auto",
  },
  container: {
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },
  survey: {
    backgroundColor: theme.palette.background.light,
    minHeight: "100vh",
  },
}));

function Survey(props) {
  const [background] = useState([
    bg_section0,
    bg_section1,
    bg_section2,
    bg_section3,
  ]);

  const {
    error,
    progress,
    match: { params: { id: surveyId } },
    survey,
    loadingSurvey,
    fetchSurveyIfNeeded,
  } = props;

  useEffect(() => {
    if (surveyId) {
      fetchSurveyIfNeeded(surveyId);
    }
  }, [surveyId]);

  const classes = useStyles();

  let content = error ? <ErrorHandler error={error} /> : <Loader />;

  const fallBackImage = background[progress.section];

  if (!loadingSurvey && survey && survey.id === surveyId && !error) {
    //SET routes Dynamically based on the settings of survey
    content = (
      <Switch>
        <Route
          path="/survey/:id/section/:sectionNumber/question/:questionNumber"
          component={QuestionSlide}
        />
        <Route
          exact
          path="/survey/:id/results/:submissionId"
          component={ResultSlide}
        />
        <Route
          exact
          path="/survey/:id/results/:submissionId/:sectionId"
          component={ResultSlide}
        />
        <Redirect to={"/"} />
      </Switch>
    );
  } else {
    content = (
      <Switch>
        <Route
          exact
          path="/:surveyLanguage"
          component={FormSlide}
        />
        <Route
          exact
          path="/"
          component={FormSlide}
        />
      </Switch>
    );
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="flex-start"
        className={classes.container}
        style={{
          backgroundImage: `url(${
            props.currentBackgroundImage
              ? props.currentBackgroundImage
              : fallBackImage // Check that a new image has been assigned otherwise use fallback
          })`,
        }}
      >
        <Grid item sm={3} lg={5}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={9} lg={7} className={classes.survey}>
          {content}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingSurvey: state.loadingSurvey,
    survey: state.survey,
    error: state.error,
    errorMessage: state.errorMessage,
    progress: state.progress,
    currentBackgroundImage: state.currentBackgroundImage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSurveyIfNeeded: (surveyId) => {
      dispatch(actions.fetchSurveyIfNeeded(surveyId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);

Survey.propTypes = {
  error: PropTypes.any,
  progress: PropTypes.object,
  survey: PropTypes.object,
  loadingSurvey: PropTypes.bool,
  getSurvey: PropTypes.func,
};
