import axios from "./axios";

export const handlePostRequest = (url, options = {}, retries = 2) => {
  // Pretty dummy function to retry a failed server request but doesn't handle
  // any results. Used in resume survey and video conference request functionalities.
  axios
    .post(url, options)
    .catch(() => {
      if (retries > 0) {
        setTimeout(() => {
          return handlePostRequest(url, options, retries - 1);
        }, 1000);
      }
    });
}
