import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "720px",
    margin: "auto",
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      marginTop: 0,
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
}));

function Slide(props) {
  const classes = useStyles();
  let propStyles = [];
  propStyles.push(props.class);
  propStyles.push(classes.root);
  return <div className={propStyles.join(" ")}>{props.children}</div>;
}

export default Slide;

Slide.propTypes = {
  children: PropTypes.node,
  class: PropTypes.string,
};
