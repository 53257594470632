import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import PropTypes from "prop-types";
import Loader from "./UI/Loader/Loader";

class RedirectToSurvey extends Component {
  static propTypes = {
    submission: PropTypes.object,
    survey: PropTypes.object,
    loadingSubmission: PropTypes.bool,
    loadingSurvey: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    let isReady = false;
    let hasSubmission = false;
    let hasSurvey = false;

    if (!props.submission) {
      props.fetchSubmissionIfNeeded(this.props.match.params.submissionId);
    } else if (!props.survey) {
      hasSubmission = true;
      this.props.fetchSurveyIfNeeded(this.props.submission.survey);
    } else {
      hasSurvey = true;
      isReady = true;
    }

    this.state = {
      hasSubmission,
      hasSurvey,
      isReady,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.isReady) {
      return;
    }

    // Check if the submission has loaded.
    if (prevProps.loadingSubmission && !this.props.loadingSubmission) {
      if (this.props.submission === null) {
        console.debug("Couldn't load the submission.");
        this.setState({ isReady: true, hasSubmission: false });
      } else {
        this.setState({ hasSubmission: true });
        this.props.fetchSurveyIfNeeded(this.props.submission.survey);
      }
    }

    // Check if the survey has loaded.
    if (prevProps.loadingSurvey && !this.props.loadingSurvey) {
      if (this.props.survey === null) {
        console.debug("Couldn't load the survey.");
        this.setState({ isReady: true, hasSurvey: false });
      } else {
        this.setState({ isReady: true, hasSurvey: true });
      }
    }
  }

  render() {
    if (!this.state.isReady) {
      return <Loader />;
    }

    if (this.state.hasSubmission && this.state.hasSurvey) {
      if (
        this.props.submission.progress < 1 &&
        this.props.submission.nextQuestion === null
      ) {
        return (
          <Redirect
            to={`/survey/${this.props.survey.id}/section/1/question/1`}
          />
        );
      } else if (
        this.props.submission.nextQuestion !== null
      ) {
        let nextQuestionId = this.props.submission.nextQuestion;
        let nextQuestion;
        for (let section of this.props.survey.sections) {
          nextQuestion = section.questions.find(
            question => question.id === nextQuestionId
          );
          if (nextQuestion) {
            break;
          }
        }
        const nextSection = this.props.survey.sections.find(
          section => section.id === nextQuestion.section
        );
        let sectionNumber = nextSection.index + 1;
        let questionNumber = nextQuestion.index + 1;
        console.log("Next Question", nextQuestion);
        return (
          <Redirect
            to={`/survey/${this.props.survey.id}/section/${sectionNumber}/question/${questionNumber}`}
          />
        );
      } else {
        return (
          <Redirect
            to={`/survey/${this.props.survey.id}/results/${this.props.submission.id}`}
          />
        );
      }
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = state => {
  return {
    submission: state.submission,
    survey: state.survey,
    loadingSubmission: state.loadingSubmission,
    loadingSurvey: state.loadingSurvey,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSubmissionIfNeeded: submissionId =>
      dispatch(actions.fetchSubmissionIfNeeded(submissionId)),
    fetchSurveyIfNeeded: surveyId =>
      dispatch(actions.fetchSurveyIfNeeded(surveyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToSurvey);
