import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import HTML from "../../../../components/UI/HTML/HTML";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    wordBreak: "break-word",
  },
  optionTextTypography: {
    fontWeight: "bold",
  },
  optionChecked: {
    color: theme.palette.primary.inverted,
    backgroundColor: theme.palette.background.active,
  },
  checkBox: {
    color: theme.palette.icon.secondary,
    alignSelf: "start",
    margin: "0 7px",
    padding: "2px",
  },
  iconsChecked: {
    color: theme.palette.icon.light,
  },
  iconsOpen: {
    transform: "rotate(-45deg)",
  },
  expansionPanelDetails: {
    width: "90%",
    paddingLeft: theme.spacing(6),
  },
}));

export default function Option(props) {
  const [isOpen, setIsOpen] = useState(null);

  const classes = useStyles();
  const { optionText, exampleText, hoverText, click, isChecked } = props;

  const answerOptionText = (
    <>
      <Typography variant="body1" className={classes.optionTextTypography}>
        {optionText}
      </Typography>
      <Typography variant="body2">
        <HTML content={exampleText} />
      </Typography>
    </>
  );

  const expandedText = (
    <Typography variant="body2">
      <HTML content={hoverText} />
    </Typography>
  );

  return (
    <ExpansionPanel
      elevation={0}
      className={`${isChecked ? classes.optionChecked : ""} ${classes.root}`}
      onChange={() => setIsOpen(!isOpen)}
    >
      <ExpansionPanelSummary
        expandIcon={
          <AddCircleOutlineOutlinedIcon
            className={isChecked ? classes.iconsChecked : ""}
            classes={{ root: `${isOpen ? classes.iconsOpen : ""}` }}
          />
        }
        aria-label="Expand"
        aria-controls="additional-actions2-content"
        id="additional-actions2-header"
      >
        <FormControlLabel
          aria-label="Select"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <Checkbox
              checkedIcon={
                <CheckBoxOutlinedIcon className={classes.iconsChecked} />
              }
              onChange={click}
              checked={isChecked}
              className={classes.checkBox}
            />
          }
          label={answerOptionText}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        {expandedText}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

Option.propTypes = {
  isChecked: PropTypes.bool,
  click: PropTypes.func,
  exampleText: PropTypes.string,
  hoverText: PropTypes.string,
  optionText: PropTypes.string,
};
