import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import axios from "../../../axios";
import Input from "../../../components/UI/Input/Input";
import Slide from "../../../components/Slides/Slide";
import HTML from "../../../components/UI/HTML/HTML";
import SurveyButton from "../../../components/UI/SurveyButton/SurveyButton";
import ErrorHandler from "../../../components/UI/ErrorHandler/ErrorHandler";
import leadFormBackgroundImage from "../../../assets/Onsagers_IP-sjekk_Arkitektur_2560x1440px.jpg";
import styles from "./FormSlide.module.css";
import CompanyInput from "../../../components/UI/Input/CompanyInput";

class FormSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailErrorMessage: "",
      title: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.title,
        value: this.props.userData.title,
      },
      firstName: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.firstName,
        value: this.props.userData.firstName,
      },
      lastName: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.lastName,
        value: this.props.userData.lastName,
      },
      emailAddress: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.emailAddress,
        value: this.props.userData.emailAddress,
      },
      phoneNumber: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.phoneNumber,
        value: this.props.userData.phoneNumber,
      },
      company: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.company,
        value: this.props.userData.company,
      },
      address: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.address,
        value: this.props.userData.address,
      },
      marketingPermission: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.marketingPermission,
        value: this.props.userData.marketingPermission,
      },
      newsletterSubscription: {
        touched: false,
        isValid: false,
        oldValue: this.props.userData.newsletterSubscription,
        value: this.props.userData.newsletterSubscription,
      },
      error: false,
    };

    this.props.setCurrentBackgroundImage(leadFormBackgroundImage);
  }

  componentDidMount() {
    const { surveyLanguage = "no" } = this.props.match.params;

    this.props.getLeadForm(surveyLanguage);
  }

  componentDidUpdate() {
    // FIXME
    // if (false && this.props.formSubmitted) {
    //   this.props.history.push(
    //     "/survey/" + this.props.match.params.id + "/section/1/question/1"
    //   );
    // }
  }

  navigateToQuestions() {
    const { submission } = this.props;

    if (submission && submission.survey)
      this.props.history.push(
        `/survey/${submission.survey}/section/1/question/1`
      );
  }

  inputChangeHandler = (event, type, isRequired) => {
    let input = { ...this.state[event.target.name] };
    switch (type) {
      case "checkbox":
        input.value = event.target.checked;
        break;

      default:
        input.value = event.target.value;
        break;
    }
    input.touched = true;
    input.hasChanged = this.state[event.target.name].oldValue !== input.value;
    input.isValid = isRequired ? this.validateInput(event, type) : true;

    this.setState({ [event.target.name]: input });
  };

  companyInputHandler = (event, type, isRequired) => {
    this.setState({
      company: {
        ...this.state.company,
        value: event.target.value,
        touched: true,
        isValid: this.validateInput(event, type) || !isRequired,
      },
    });
  };

  companySelectHandler = (company) => {
    this.setState({
      company: {
        ...this.state.company,
        value: company.name,
        companyId: company.companyId,
      },
    });
  };

  validateInput(event, type) {
    switch (type) {
      case "text":
        return event.target.value !== "";

      case "email":
        const emailReg = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        return emailReg.test(event.target.value);

      case "checkbox":
        return event.target.checked;

      default:
        return false;
    }
  }

  validateForm() {
    let formValid = true;
    for (let i = 0; i < this.props.leadForm.formInputs.length; i++) {
      //validate only inputs that are required
      if (this.props.leadForm.formInputs[i].isRequired) {
        formValid = this.state[this.props.leadForm.formInputs[i].inputName]
          .isValid;

        if (!formValid) {
          let input = {
            ...this.state[this.props.leadForm.formInputs[i].inputName],
          };
          input.touched = true;
          this.setState({
            [this.props.leadForm.formInputs[i].inputName]: input,
          });
          break;
        }
      }
    }

    return formValid;
  }

  continueHandler = (event) => {
    event.preventDefault();
    const formValid = this.validateForm();

    if (formValid) {
      let userData = this.getUserData();

      if (this.props.userData.id) {
        this.updateUserData(userData);
      } else {
        this.createSubmission(userData);
      }
    }
  };

  updateUserData = (userData) => {
    axios
      .patch(`/userData/${this.props.userData.id}`, userData)
      .then((response) => {
        this.props.updateFormData(response.data, this.props.submission);
        this.navigateToQuestions();
      })
      .catch((error) => {
        this.props.setError(error);
      });
  };

  createSubmission = (userData) => {
    const {
      company: { companyId },
    } = this.state;
    const { surveyLanguage = "no" } = this.props.match.params;

    axios
      .post("/userData", userData)
      .then((response) => {
        // TODO No reason to check this really.
        if (response.data.id) {
          // TODO Maybe just use the response data
          userData.id = response.data.id;
          let submissionData = {
            companyId,
            lang: surveyLanguage,
            userData: userData.id,
          };

          if (process.env.NODE_ENV === "development") {
            console.warn(
              "App is running in development environment; lead data will not be sent to Pardot."
            );
            submissionData.isTest = true;
          }

          //after user data is saved submission can be created
          axios
            .post("/submission", submissionData)
            .then((response) => {
              this.props.updateFormData(userData, response.data);
              this.navigateToQuestions();
            })
            .catch((error) => {
              this.props.setError(error);
            });
        } else {
          this.props.setError({ message: "Could not store form data." });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.debug(
            "Error msg from backend =",
            error.response.data.emailAddress[0]
          );
          this._setEmailError(error.response.data.emailAddress[0]);
        } else {
          this.props.setError(error);
        }
      });
  };

  _setEmailError(newError) {
    this.setState({ emailErrorMessage: newError });
  }

  getUserData = () => {
    let data = {};

    for (let field in this.state) {
      data[field] = this.state[field].value;
    }
    return data;
  };

  prevHandler = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  };

  render() {
    console.debug("Rendering FormSlide");
    let html,
      formFields,
      buttonText = null;

    if (this.props.leadForm) {
      const { content, formInputs, startButtonText } = this.props.leadForm;
      html = <HTML content={content} />;

      // Reorder inputs in the lead form because they come in random order from the backend
      const nameOrder = [
        "company",
        "emailAddress",
        "address",
        "title",
        "firstName",
        "lastName",
        "phoneNumber",
        "marketingPermission",
        "newsletterSubscription",
      ];
      let output = [];
      // Sort the array of objects based on nameOrder array
      for (const input of formInputs) {
        const inputIndex = nameOrder.findIndex(
          (value) => value === input.inputName
        );
        if (inputIndex >= 0) {
          output[inputIndex] = input;
        }
      }
      // Filter empty elements
      output = output.filter(() => true);

      formFields = output.map((item) => {
        if (item.inputName === "company") {
          return (
            <CompanyInput
              key={item.inputName}
              name={item.inputName}
              placeholder={item.inputLabel}
              isValid={this.state[item.inputName].isValid}
              touched={this.state[item.inputName].touched}
              onChange={(event) =>
                this.companyInputHandler(event, item.inputType, item.isRequired)
              }
              companySelectHandler={this.companySelectHandler}
              value={this.state[item.inputName].value}
            />
          );
        } else {
          return (
            <Input
              key={item.inputName}
              inputType={item.inputType}
              isValid={this.state[item.inputName].isValid}
              changed={(event) =>
                this.inputChangeHandler(event, item.inputType, item.isRequired)
              }
              name={item.inputName}
              placeholder={item.inputLabel}
              label={item.inputLabel}
              touched={this.state[item.inputName].touched}
              //map values from userData redux
              value={this.state[item.inputName].value}
              emailErrorMessage={this.state.emailErrorMessage}
            />
          );
        }
      });

      buttonText = <SurveyButton buttonLabel={startButtonText} />;
    }

    let error = null;

    if (this.props.error) {
      //reset UI components and show error messsage
      html = null;
      formFields = null;
      buttonText = null;
      error = <ErrorHandler error={this.props.error} />;
    }

    return (
      <Slide class={styles.FormSlide}>
        {html}
        <form className={styles.LeadForm} onSubmit={this.continueHandler}>
          {formFields}
          {buttonText}
        </form>
        {error}
      </Slide>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    ...state.settings,
    userData: state.userData,
    submission: state.submission,
    formSubmitted: state.formSubmitted,
    survey: state.survey,
    error: state.error,
    progress: state.progress,
    leadForm: state.leadForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFormData: (userData, submissionData) =>
      dispatch(actionTypes.updateFormData(userData, submissionData)),
    setError: (error) => dispatch(actionTypes.setError(error)),
    getLeadForm: (surveyLanguage) =>
      dispatch(actionTypes.getLeadForm(surveyLanguage)),
    setCurrentBackgroundImage: (currentBackgroundImage) =>
      dispatch(actionTypes.setCurrentBackgroundImage(currentBackgroundImage)),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(FormSlide);
