import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import { handlePostRequest } from '../../../helpers.js';

const useStyles = makeStyles(theme => ({
  modalHeaderTypography: {
    maxWidth: "460px",
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
  buttonGrid: {
    alignItems: "center",
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  dialogButton: {
    borderRadius: "1.4rem",
    minWidth: "150px",
    minHeight: "40px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.9",
    },
    margin: theme.spacing(2, 0),
  },
  dialogButtonDescription: {
    maxWidth: "250px",
    color: theme.palette.primary.main,
  },
  thankYouGrid: {
    margin: 'auto',
  },
}));

const texts = {
  NO: {
    basicHeader: 'Har du spørsmål eller trenger hjelp med utfyllingen?',
    confirmedHeader: 'Takk for henvendelsen!',
    yesButton: 'Ja, takk',
    yesButtonDescription: 'Vi kontakter deg om kort tid.',
    yesThankYou: (
      <>
        <p>
          Vi vil kontakte deg via mail for å hjelpe deg videre med IP-sjekken.
        </p>
        <p>
          Mvh<br />
          Onsagers IP-sjekk team
        </p>
      </>
    ),
    noButton: 'Nei, takk',
    noButtonDescription: 'Vi sender deg en lenke, slik at du kan fortsette senere.',
    noThankYou: (
      <>
        <p>
          Vi vil sende deg en lenke til din IP-sjekk slik at du kan fullføre den når du har anledning.
        </p>
        <p>
          Mvh<br />
          Onsagers IP-sjekk team
        </p>
      </>
    ),
  },
  EN: {
    basicHeader: 'Do you have questions or need help with filling out the survey?',
    confirmedHeader: 'Thank you for contacting us!',
    yesButton: 'Yes please',
    yesButtonDescription: `We'll contact you soon.`,
    yesThankYou: (
      <>
        <p>
          We will contact you by email and help you proceed with the survey.
        </p>
        <p>
          Regards,<br />
          Onsagers IP-check team
        </p>
      </>
    ),
    noButton: 'No, thank you',
    noButtonDescription: `We'll send you a link so you can continue later.`,
    noThankYou: (
      <>
        <p>
          We will send you a link of your IP-check so you can fill out the survey when suitable.
        </p>
        <p>
          Regards,<br />
          Onsagers IP-check team
        </p>
      </>
    ),
  },
};

export default function ContinueLaterDialog({ surveyLanguage, submissionId }) {
  const classes = useStyles();
  const [whichConfirmClicked, setWhichConfirmClicked] = useState();

  useEffect(() => {
    return () => setWhichConfirmClicked(undefined);
  }, []);

  const handleYesClick = () => {
    handlePostRequest(`/submission/${submissionId}/finish_later?contactMe=true`, { contactMe: true });
    setWhichConfirmClicked('yes');
  };

  const handleNoClick = () => {
    handlePostRequest(`/submission/${submissionId}/finish_later?contactMe=false`);
    setWhichConfirmClicked('no');
  };

  const header = whichConfirmClicked ?
    <>{texts[surveyLanguage].confirmedHeader}</> :
    <>{texts[surveyLanguage].basicHeader}</>;

  const content = whichConfirmClicked ? (
    <Grid item xs={9} className={classes.thankYouGrid}>
      {texts[surveyLanguage][`${whichConfirmClicked}ThankYou`]}
    </Grid>
  ) : (
    <>
      <Grid item xs={12} sm={6} className={classes.buttonGrid}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.dialogButton}
          onClick={handleYesClick}
        >
          {texts[surveyLanguage].yesButton}
        </Button>
        <Typography
          variant="body1"
          className={classes.dialogButtonDescription}
        >
          {texts[surveyLanguage].yesButtonDescription}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.buttonGrid}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.dialogButton}
          onClick={handleNoClick}
        >
          {texts[surveyLanguage].noButton}
        </Button>
        <Typography
          variant="body1"
          className={classes.dialogButtonDescription}
        >
          {texts[surveyLanguage].noButtonDescription}
        </Typography>
      </Grid>
    </>
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography
          id="modal-header"
          variant="h2"
          className={classes.modalHeaderTypography}
        >
          {header}
        </Typography>
      </Grid>
      {content}
    </>
  );
}

ContinueLaterDialog.propTypes = {
  surveyLanguage: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
};
