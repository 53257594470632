import React from "react";
import HTML from "../../../components/UI/HTML/HTML";
import { Link, Typography, makeStyles } from "@material-ui/core";
import Model from "../Model/Model";
import PropTypes from "prop-types";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  overviewLink: {
    display: "inline-block",
    margin: theme.spacing(4, 0),
  },
  modelWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  optionText: {
    margin: theme.spacing(2, 0),
  },
  longRecommendation: {
    margin: theme.spacing(2, 0),
  },
}));

const goBackText = {
   NO: 'Tilbake til oppsummering',
   EN: 'Back to summary',
};

export default function ResultDetails(props) {
  const { surveyLanguage } = props;
  const classes = useStyles();
  let match = useRouteMatch();


  const goBackLink = (
    <Link
      variant="body1"
      component={RouterLink}
      to={`/survey/${match.params.id}/results/${match.params.submissionId}`}
      className={classes.overviewLink}
    >
      {goBackText[surveyLanguage]}
    </Link>
  );

  const { result } = props;

  return (
    <div>
      {goBackLink}
      <Typography variant="h1" color="primary">
        {result.name}
      </Typography>
      <HTML content={result.genericText} />

      <div className={classes.modelWrapper}>
        <Model sectionResult={props.result} surveyLanguage={surveyLanguage} />
      </div>

      <Typography variant="h2" color="primary" className={classes.optionText}>
        {result.optionText}
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        className={classes.longRecommendation}
      >
        <HTML content={result.longRecommendation} />
      </Typography>
      {goBackLink}
    </div>
  );
}

ResultDetails.propTypes = {
  result: PropTypes.object.isRequired,
  surveyLanguage: PropTypes.string.isRequired,
};
