import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ProgressBar from "../ProgressBar/ProgressBar";
import {
  makeStyles,
  Button,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import Dialog from '../Dialog/Dialog'
import ContinueLaterDialog from '../Dialog/ContinueLaterDialog'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(7, -0.5, 3),
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  slideCount: {
    display: "inline-block",
    marginLeft: "10px",
    marginRight: "10px",
  },
  nextButton: {
    borderRadius: "1.4rem",
    minWidth: "100px",
    minHeight: "40px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
  },
  nextButtonTypography: {
    color: theme.palette.primary.inverted,
  },
  prevButton: {
    color: theme.palette.primary.inverted,
    backgroundColor: theme.palette.primary.main,
    fontSize: "1.6rem",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    '&:disabled': {
      backgroundColor: 'lightgrey',
      color: theme.palette.primary.inverted,
    },
  },
  continueLater: {
    margin: theme.spacing(3, 0, -0.5),
    textAlign: 'right',
  }
}));

const continueLaterText = {
  NO: 'Lagre og fortsette senere',
  EN: 'Save and continue later',
}

export default function Navigation(props) {
  const {
    progress,
    surveyLanguage,
    submissionId,
    buttonLabel,
    prevHandler,
    nextHandler,
   } = props;

  const classes = useStyles();

  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

  useEffect(() => {
    setAreButtonsDisabled(false);
  }, [progress]);

  const handleClick = (func) => {
    if (areButtonsDisabled) {
      return;
    }

    setAreButtonsDisabled(true);
    func();
  };

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={12}>
        <ProgressBar data={progress.totalProcentage} />
      </Grid>
      <Grid item xs>
        <IconButton
          disabled={progress.currentQuestion === 1 || areButtonsDisabled}
          aria-label="previous question"
          className={classes.prevButton}
          onClick={() => handleClick(prevHandler)}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Typography variant="body1" className={classes.slideCount}>
          {progress.total}
        </Typography>
      </Grid>
      <Grid item xs className={classes.buttonGrid}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={areButtonsDisabled}
          className={classes.nextButton}
          onClick={() => handleClick(nextHandler)}
        >
          <Typography
            variant="button"
            className={classes.nextButtonTypography}
          >
            {buttonLabel}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.continueLater}>
        <Dialog displayText={continueLaterText[surveyLanguage]}>
          <ContinueLaterDialog
            surveyLanguage={surveyLanguage}
            submissionId={submissionId}
          />
        </Dialog>
      </Grid>
    </Grid>
  );
}

Navigation.propTypes = {
  progress: PropTypes.object,
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  buttonLabel: PropTypes.string,
  surveyLanguage: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
};
