import React from "react";
import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "& p": {
      display: "inline-block",
      textTransform: "capitalize",
    },
  },
  sectionNumber: {
    display: "inline-block",
    marginRight: theme.spacing(3),
  },
  border: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    margin: `${theme.spacing(0.5)}px 0px`,
  },
}));

const sectionText = {
  NO: 'Del',
  EN: 'Section',
};

export default function SectionHeader(props) {
  const { surveyLanguage } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.sectionNumber}>
        {sectionText[surveyLanguage]} {props.progress.section + 1}
      </Typography>
      <Typography variant="body1">
        {props.sections[props.progress.section].name}
      </Typography>

      <div className={classes.border} />
    </div>
  );
}

SectionHeader.propTypes = {
  sectionName: PropTypes.string,
  sectionNumber: PropTypes.number,
  surveyLanguage: PropTypes.string.isRequired,
};
