import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/onsagers_logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1170px",
    width: "100%",
    display: "block",
    margin: "auto",
    padding: "20px 10px 0px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "160px",
    },
    boxSizing: "border-box",
    position: "sticky",
    top: "0",
  },
  logo: {
    maxHeight: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      maxHeight: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "180px",
      position: "sticky",
      top: "0",
    },
    display: "block",
    margin: "auto",
  },
}));

export default function Header() {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <img className={classes.logo} src={logo} alt="company_logo" />
    </header>
  );
}
