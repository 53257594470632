import React, { useState } from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { handlePostRequest } from '../../../helpers.js';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  content: {
    width: '100%',
  },
  dialogButton: {
    borderRadius: "1.4rem",
    minWidth: "150px",
    minHeight: "40px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.9",
    },
    margin: theme.spacing(2, 0),
  },
}));

const texts = {
  NO: {
    basicHeader: 'Bestill gratis gjennomgang med vår IP-ekspert',
    confirmedHeader: 'Takk for henvendelsen!',
    button: 'Bestill gjennomgang her',
    basicText: (
      <>
        <p>
          Ønsker du å vite mer om hva som er og kan bli din bedrifts viktigste konkurransefortrinn? Bestill en gratis gjennomgang i dag og lær mer om hvordan du kan bygge og sikre din bedrifts konkurransekraft.
        </p>
      </>
    ),
    confirmedText: (
      <>
        <p>
          Vi vil kontakte deg via mail.
        </p>
        <p>
          Mvh<br />
          Onsagers IP-sjekk team
        </p>
      </>
    ),
  },
  EN: {
    basicHeader: 'Book an assessment with our IP expert – free of charge',
    confirmedHeader: 'Thank you for contacting us!',
    button: 'Book here',
    basicText: (
      <>
        <p>
          Want to learn more about your company's current and potential competitive advantages? Book a free assessment today and learn how you can build and secure your company’s competitiveness.
        </p>
      </>
    ),
    confirmedText: (
      <>
        <p>
          We will contact you by email.
        </p>
        <p>
          Regards,<br />
          Onsagers IP-check team
        </p>
      </>
    ),
  },
};

const BookAnAssessmentDialog = ({ submissionId, surveyLanguage }) => {
  const classes = useStyles();
  const [confirmClicked, setConfirmClicked] = useState(false);

  const handleClick = () => {
    handlePostRequest(`/submission/${submissionId}/video_conference_request`);
    setConfirmClicked(true);
  };

  const header = confirmClicked ?
    <>{texts[surveyLanguage].confirmedHeader}</> :
    <>{texts[surveyLanguage].basicHeader}</>;

  const content = confirmClicked ?
    <>{texts[surveyLanguage].confirmedText}</> :
    <>{texts[surveyLanguage].basicText}</>;

  return (
    <>
      <Typography variant="h2" className={classes.header}>{header}</Typography>
      <Typography variant="body1" component="div" className={classes.content}>{content}</Typography>
      {!confirmClicked && (
          <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.dialogButton}
          onClick={handleClick}
        >
          {texts[surveyLanguage].button}
        </Button>
      )}
    </>
  );
};
export default BookAnAssessmentDialog;
