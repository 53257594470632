import React from "react";

import HTML from "../../UI/HTML/HTML";
import styles from "./Input.module.css";

const input = props => {
  let emailError = null;
  if (props.emailErrorMessage) {
    emailError = (
      <p className={styles.ErrorMessage}>{props.emailErrorMessage}</p>
    );
  }

  let input = null;
  let invalidClass = null;

  if (props.isValid !== props.touched) {
    invalidClass = styles.Invalid;
  }

  switch (props.inputType) {
    case "text":
      input = (
        <input
          className={[styles.InputElement, invalidClass].join(" ")}
          type="text"
          value={props.value}
          placeholder={props.placeholder}
          name={props.name}
          onChange={props.changed}
        />
      );
      break;

    case "email":
      input = (
        <>
          <input
            className={[styles.InputElement, invalidClass].join(" ")}
            type="email"
            value={props.value}
            placeholder={props.placeholder}
            name={props.name}
            onChange={props.changed}
          />
          {emailError}
        </>
      );
      break;

    case "checkbox":
      input = (
        <label className={styles.Container}>
          <HTML class={styles.Label} content={props.label} />
          <input
            type="checkbox"
            name={props.name}
            value={props.value}
            onChange={props.changed}
          />
          <span className={[styles.Checkmark, invalidClass].join(" ")}></span>
        </label>
      );
      break;
    default:
      break;
  }

  return input;
};

export default input;
