import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Survey from "./containers/Survey/Survey";
import Helmet from "react-helmet";
import RedirectToSurvey from "./components/RedirectToSurvey";

class App extends Component {
  render() {
    return (
      <>
        {process.env.NODE_ENV === "production" && (
          <Helmet>
            <script type="text/javascript">{`
                  piAId = '482561';
                  piCId = '16661';
                  piHostname = 'pi.pardot.com';

                  (function() {
                                  function async_load() {
                                    var s = document.createElement('script'); s.type = 'text/javascript';
                                    s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                                    var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
                                  }
                    if (window.attachEvent) {window.attachEvent('onload', async_load); }
                    else {window.addEventListener('load', async_load, false); }
                              })();
                  `}</script>
            <script type="text/javascript">{`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NNVZ884');
                  `}</script>
          </Helmet>
        )}
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/en"
              component={Survey}
            />
            <Route
              exact
              path="/"
              component={Survey}
            />
            <Route
              exact
              path="/submission/:submissionId"
              component={RedirectToSurvey}
            />
            <Route path="/survey/:id/" component={Survey} />
            <Redirect to={"/"} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
