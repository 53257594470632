import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  total: {
    display: "block",
    height: "5px",
    backgroundColor: theme.palette.progressBar.light,
    borderRadius: "2px",
    position: "relative",
  },
  progress: {
    position: "absolute",
    width: "0%",
    height: "100%",
    backgroundColor: theme.palette.progressBar.dark,
    borderRadius: "2px",
    left: "0px",
    top: "0px",
  },
}));

export default function ProgressBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.total}>
        <div
          className={classes.progress}
          style={{ width: `${props.data}%` }}
        ></div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  data: PropTypes.string,
};
