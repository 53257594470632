import React from "react";
import { Link } from "react-router-dom";
import pdf from "../../../assets/pdf.png";
import { makeStyles, Grid, Button, Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import HTML from "../../../components/UI/HTML/HTML";
import Model from "../Model/Model";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  resultIntro: {
    color: theme.palette.primary.main,
    "& h2": theme.typography.h1,
    "& p": theme.typography.body1,
  },
  companyName: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
  modelHeading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  modelWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
  },
  sectionOverview: {
    margin: theme.spacing(3, 0),
  },
  sectionNameWrapper: {
    margin: theme.spacing(2, 0),
    display: "flex",
    marginLeft: theme.spacing(-2),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(-5),
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(-6.5),
    },
  },
  optionText: {
    fontFamily: "Gotham Bold",
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
  },
  moreButton: {
    margin: theme.spacing(2, 0),
    textTransform: "none",
    borderRadius: "1.4rem",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
  },
  moreButtonLink: {
    textDecoration: "none",
  },
  yellow: {
    color: theme.palette.model.yellow,
    fontSize: "40px",
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1.5),
    },
  },
  red: {
    color: theme.palette.model.red,
    fontSize: "40px",
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1.5),
    },
  },
  green: {
    color: theme.palette.model.green,
    fontSize: "40px",
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1.5),
    },
  },
  grey: {
    color: theme.palette.model.grey,
    fontSize: "40px",
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(1.5),
    },
  },
  downloadLink : {
    display: 'flex',
    alignItems: 'center',
    margin: '-1em 4em 5em 0em',
    color: theme.palette.primary.main,
    fontSize:'1.2em',
  },
  downloadArrow : {
    marginRight:'1em',
  },
  pdfBox : {
    marginTop:'10em',
    marginBottom:'4em'
  },
  pdfImg : {
    width:'100%'
  },
  pdfBoxTitle : {
    marginBottom:'0.4em'
  },
  pdfBoxLink : {
    display: 'flex',
    alignItems: 'center',
    margin: '0em',
    color: theme.palette.primary.main,
    fontSize:'1.2em'
  }
}));

const readMoreText ={
  NO: 'Les mer',
  EN: 'Read more',
};

const areaRelevanceText = {
  NO: 'IP-områdenes relevans',
  EN: 'Relevance of IP in your business areas',
}

const pdfDownloadText = {
  NO: 'Last ned utvidet rapport (PDF)',
  EN: 'Download extended report (PDF)',
}

const pdfDownloadBoxTitle = {
  NO: 'Last ned utvidet rapport',
  EN: 'Download extended report',
}

const pdfDownloadBoxText = {
  NO: 'Her får du en fyldig gjennomgang av dine resultater, samt innspill til hvordan du kan bruke rapporten i arbeidet med øke din bedrifts konkurransekraft.',
  EN: 'Here you get a brief overview of your results, as well as input on how you can use the report in the work of increasing your company\'s competitiveness.',
}

const pdfDownloadBoxLink = {
  NO: 'Last ned som PDF',
  EN: 'Download as PDF',
}

let baseURL;
switch (process.env.NODE_ENV) {
  case "production":
      baseURL = "https://api.onsagers.no/api"
      break;
  case "development":
      baseURL = "https://onsagers.isotammi.fi/api";
      break;
  default:
      baseURL = "https://onsagers.isotammi.fi/api";
}

export default function ResultOverview(props) {
  const classes = useStyles();
  const match = useRouteMatch();
  const { resultIntro, resultsBySection, surveyLanguage, companyName } = props;

  const sectionOverview = resultsBySection.map((section) => {
    let shortRecommendation = section.shortRecommendation;
    if (shortRecommendation.length > 300) {
      shortRecommendation = `${shortRecommendation.slice(0, 297)}...`;
    }
    return (
      <div className={classes.sectionOverview} key={section.sectionId}>
        <div className={classes.sectionNameWrapper}>
          <PlayArrowIcon className={`${classes[section.color]}`} />
          <Typography variant="h2" color="primary">
            {section.name}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.optionText}>
          {section.optionText}
        </Typography>
        <Typography>
          <HTML content={shortRecommendation} />
        </Typography>
        <Link
          to={`${match.url}/${section.sectionId}`}
          className={classes.moreButtonLink}
        >
          <Button
            className={classes.moreButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            {readMoreText[surveyLanguage]}
          </Button>
        </Link>
      </div>
    );
  });

  return (
    <>
      <div className={classes.resultIntro}>
        <HTML content={resultIntro}></HTML>
      </div>
      <Typography variant="h2" align="center" className={classes.companyName}>
        {companyName}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="primary"
        className={classes.modelHeading}
      >
        {areaRelevanceText[surveyLanguage]}
      </Typography>
      <div className={classes.modelWrapper}>
        <Model resultsBySection={resultsBySection} surveyLanguage={surveyLanguage} />
      </div>
      {surveyLanguage != 'EN' &&
      <a className={classes.downloadLink} href={`${baseURL}/submission/${match.params.submissionId}/download_pdf`} download>
        <svg className={classes.downloadArrow} width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Korrektur-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="IP-sjekk/del-1/oppsummering/UPDATE" transform="translate(-1140.000000, -2311.000000)">
                    <rect id="Mask" x="623.5" y="2066" width="716" height="281"></rect>
                    <g id="Group-3" transform="translate(1140.000000, 2311.000000)">
                        <text id="download-pdf" fontFamily="Gotham-Book, Gotham" fontSize="16" fontWeight="300" line-spacing="24" fill="#3C3D48" textDecoration="underline">
                            <tspan x="53.992" y="21">{pdfDownloadText[surveyLanguage]}</tspan>
                        </text>
                        <g id="Symbol/download">
                            <path d="M40,20 C40,31.0456604 31.0456604,40 20,40 C8.95433962,40 0,31.0456604 0,20 C0,8.95433962 8.95433962,0 20,0 C31.0456604,0 40,8.95433962 40,20" id="Fill-1" fill="#3C3D48"></path>
                            <path d="M30.4659958,17.4060225 C29.7539902,16.7186141 28.5992223,16.7186141 27.8875206,17.4060225 L21.8234697,23.260582 L21.8234697,9.76032859 C21.8234697,8.78833382 21.0069272,8 20.0001519,8 C18.9930728,8 18.1768342,8.78833382 18.1768342,9.76032859 L18.1768342,23.260582 L12.1124794,17.4060225 C11.4007777,16.7186141 10.2460098,16.7186141 9.53400419,17.4060225 C8.8219986,18.0934308 8.8219986,19.2080122 9.53400419,19.8954205 L20.0001519,30 L30.4659958,19.8954205 C31.1780014,19.2080122 31.1780014,18.0934308 30.4659958,17.4060225" id="Fill-3" fill="#FEFEFE"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        {pdfDownloadText[surveyLanguage]}
      </a>}
      {sectionOverview}

      {surveyLanguage != 'EN' &&
      <Grid className={classes.pdfBox} container spacing={3}>
        <Grid item xs={12} md={5}>
          <img className={classes.pdfImg} src={pdf} alt="pdf_img" />
        </Grid>
        <Grid container item xs={12} md={7}>
          <Typography className={classes.pdfBoxTitle} variant="h2" color="primary">
            {pdfDownloadBoxTitle[surveyLanguage]}
          </Typography>
          <Typography variant="body1">
            {pdfDownloadBoxText[surveyLanguage]}
          </Typography>
        </Grid>
        <Grid container item xs={12} justify="flex-end">
          <a className={classes.pdfBoxLink} href={`${baseURL}/submission/${match.params.submissionId}/download_pdf`} download>
            <svg className={classes.downloadArrow} width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Korrektur-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="IP-sjekk/del-1/oppsummering/UPDATE" transform="translate(-1140.000000, -2311.000000)">
                        <rect id="Mask" x="623.5" y="2066" width="716" height="281"></rect>
                        <g id="Group-3" transform="translate(1140.000000, 2311.000000)">
                            <text id="download-pdf" fontFamily="Gotham-Book, Gotham" fontSize="16" fontWeight="300" line-spacing="24" fill="#3C3D48" textDecoration="underline">
                                <tspan x="53.992" y="21">{pdfDownloadText[surveyLanguage]}</tspan>
                            </text>
                            <g id="Symbol/download">
                                <path d="M40,20 C40,31.0456604 31.0456604,40 20,40 C8.95433962,40 0,31.0456604 0,20 C0,8.95433962 8.95433962,0 20,0 C31.0456604,0 40,8.95433962 40,20" id="Fill-1" fill="#3C3D48"></path>
                                <path d="M30.4659958,17.4060225 C29.7539902,16.7186141 28.5992223,16.7186141 27.8875206,17.4060225 L21.8234697,23.260582 L21.8234697,9.76032859 C21.8234697,8.78833382 21.0069272,8 20.0001519,8 C18.9930728,8 18.1768342,8.78833382 18.1768342,9.76032859 L18.1768342,23.260582 L12.1124794,17.4060225 C11.4007777,16.7186141 10.2460098,16.7186141 9.53400419,17.4060225 C8.8219986,18.0934308 8.8219986,19.2080122 9.53400419,19.8954205 L20.0001519,30 L30.4659958,19.8954205 C31.1780014,19.2080122 31.1780014,18.0934308 30.4659958,17.4060225" id="Fill-3" fill="#FEFEFE"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            {pdfDownloadBoxLink[surveyLanguage]}
          </a>
        </Grid>
      </Grid>}
    </>
  );
}

ResultOverview.propTypes = {
  resultIntro: PropTypes.string,
  resultsBySection: PropTypes.array.isRequired,
  surveyLanguage: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};
