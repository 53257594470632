import React from "react";
import styles from "./SurveyButton.module.css";

const surveyButton = props => {
  return (
    <div
      className={styles.SurveyButtonContainer}
      style={{ textAlign: props.align }}
    >
      <button
        type="submit"
        className={props.className ? props.className : styles.SurveyButton}
        style={{
          backgroundColor: props.color,
          color: props.color
            ? props.color === "#FFE300"
              ? "#4e4e4e"
              : "#ffffff"
            : "#4e4e4e",
        }}
      >
        {props.buttonLabel}
      </button>
    </div>
  );
};
export default surveyButton;
