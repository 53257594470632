import React from "react";

/*
NOTE: Experience & income have a -1 offset in their viewBox to remove the "gap" between the shapes.
Most likely caused by antialiasing.
*/

export const ModelSVG = ({
  svg,
  surveyLanguage = 'NO',
  imageFill = "#878787",
  textFill = "#fff",
  bgFill = "none",
}) => {
const svgs = {
  experience: <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 0 132 148"
  >
    <rect fill={bgFill} x="-0.23" y="0.07" width="100%" height="100%" />
    <path
      fill={imageFill}
      d="M57.26,133.54c0-48.49,28.55-78.61,74.5-78.61h0V16.57h0c-66.95,0-111.94,47-111.94,117v14.53H57.26Z"
    />
  {surveyLanguage === 'NO' &&
    <>
      <path
        fill={textFill}
        d="M44,120.36h0a6.14,6.14,0,0,1,1.64-12.17h0A6.14,6.14,0,0,1,44,120.36Zm1.31-9.53h0a3.5,3.5,0,0,0-4.07,3,3.45,3.45,0,0,0,3.09,3.92h0a3.51,3.51,0,0,0,4.07-3A3.45,3.45,0,0,0,45.27,110.83Z"
      />
      <path
        fill={textFill}
        d="M40.93,102.76l1.24-4.56c.72-2.66,2.73-3.84,5-3.22h0c2.58.7,3.37,3.07,2.69,5.57l-.5,1.86,3.34.91-.66,2.45Zm6.81-2.88a1.78,1.78,0,0,0-1.16-2.4h0c-1.1-.29-1.89.31-2.23,1.54l-.51,1.89,3.41.92Z"
      />
      <path
        fill={textFill}
        d="M44.79,89.76l1.82-4.35C47.67,82.86,49.82,82,52,82.86h0c2.46,1,2.94,3.49,1.94,5.88l-.74,1.78,3.2,1.33-1,2.35Zm7.13-2a1.79,1.79,0,0,0-.84-2.53h0c-1-.44-1.92,0-2.41,1.23l-.75,1.81,3.26,1.36Z"
      />
      <path
        fill={textFill}
        d="M50.41,77.32l1.3-2.18,7.94,4.73,3-5,2,1.18-4.25,7.13Z"
      />
      <path
        fill={textFill}
        d="M57.36,66.39l5.56-6.71,1.74,1.44-3.95,4.77,1.81,1.49L66,63.19l1.74,1.44-3.47,4.2,1.87,1.54,4-4.83L71.87,67l-5.61,6.78Z"
      />
      <path
        fill={textFill}
        d="M65.86,56.55,68,54.73,75.56,59l-3-8.16,2.09-1.77,4,11.89L77,62.39Z"
      />
      <path
        fill={textFill}
        d="M78.62,46.45l7.51-4.4,1.15,2-5.34,3.13,1.18,2,4.7-2.75,1.14,2-4.7,2.75,1.23,2.09L90.9,50,92,52l-7.59,4.45Z"
      />
      <path
        fill={textFill}
        d="M93.63,49.35l.73-2.23a5.3,5.3,0,0,0,3.72,0c1-.38,1.4-1,1.15-1.63v0c-.24-.59-.73-.76-2.7-.51-2.38.31-4,.26-4.86-1.87v0c-.77-2,.28-3.86,2.48-4.73a6.7,6.7,0,0,1,4.53-.21l-.53,2.27a5.35,5.35,0,0,0-3.21,0c-.9.36-1.22,1-1,1.48v0c.28.71.83.76,2.88.5,2.4-.29,3.94,0,4.68,1.88v0c.84,2.13-.31,4-2.63,4.88A7.3,7.3,0,0,1,93.63,49.35Z"
      />
    </>
  }
  {surveyLanguage === 'EN' &&
    <g id="Group-24" transform="translate(37.000000, 33.000000)" fill={textFill}>
      <polygon id="Fill-4" points="0.9222 96.8447 1.2812 88.1417 3.5392 88.2357 3.2842 94.4157 5.6252 94.5127 5.8492 89.0737 8.1082 89.1657 7.8832 94.6057 10.3062 94.7057 10.5652 88.4427 12.8232 88.5347 12.4612 97.3217"></polygon>
      <polygon id="Fill-6" points="3.1336 73.6094 8.1606 78.2424 14.5856 75.1104 14.1576 78.0484 9.8656 80.0424 13.4086 83.1924 12.9926 86.0484 7.7606 81.2184 1.5876 84.2214 2.0156 81.2824 6.0696 79.4384 2.7166 76.4654"></polygon>
      <path d="M10.9124,66.3369 C11.2444,65.1109 10.7044,64.1959 9.7494,63.9389 L9.7174,63.9299 C8.6194,63.6329 7.8234,64.2369 7.4914,65.4639 L6.9794,67.3589 L10.3874,68.2799 L10.9124,66.3369 Z M4.1024,69.2129 L5.3334,64.6579 C6.0524,61.9989 8.0644,60.8159 10.3414,61.4319 L10.3734,61.4409 C12.9534,62.1379 13.7484,64.5069 13.0734,67.0069 L12.5694,68.8699 L15.9134,69.7749 L15.2504,72.2269 L4.1024,69.2129 Z" id="Fill-8"></path>
      <polygon id="Fill-10" points="7.9583 56.2139 11.3113 48.1739 13.3973 49.0439 11.0163 54.7539 13.1783 55.6559 15.2733 50.6309 17.3603 51.5009 15.2643 56.5259 17.5023 57.4589 19.9163 51.6729 22.0023 52.5429 18.6173 60.6589"></polygon>
      <path d="M21.053,42.1992 C21.698,41.1432 21.503,40.1942 20.673,39.6872 L20.644,39.6692 C19.715,39.1022 18.841,39.4582 18.197,40.5142 L16.882,42.6692 L19.713,44.3962 L21.053,42.1992 Z M13.601,43.6442 L16.351,39.1372 C17.115,37.8842 18.06,37.1272 19.103,36.8742 C19.988,36.6602 20.942,36.8372 21.872,37.4042 L21.9,37.4212 C23.491,38.3922 23.968,39.8612 23.667,41.3982 L28.657,41.1372 L27.111,43.6732 L22.668,43.8602 L21.628,45.5642 L24.783,47.4892 L23.46,49.6582 L13.601,43.6442 Z" id="Fill-12"></path>
      <polygon id="Fill-14" points="21.6531 31.5186 23.2461 29.5396 32.2421 36.7796 30.6491 38.7586"></polygon>
      <polygon id="Fill-16" points="26.7654 25.4229 33.0564 19.3989 34.6194 21.0309 30.1524 25.3099 31.7724 27.0019 35.7034 23.2359 37.2674 24.8679 33.3354 28.6339 35.0124 30.3849 39.5404 26.0489 41.1044 27.6819 34.7524 33.7639"></polygon>
      <polygon id="Fill-18" points="37.1863 16.0396 39.1413 14.7486 47.5763 17.6996 43.6573 11.7666 45.7493 10.3846 52.1143 20.0206 50.3103 21.2126 41.5973 18.1686 45.6443 24.2946 43.5513 25.6766"></polygon>
      <path d="M52.6463,13.2505 L52.6333,13.2205 C51.3403,10.2025 52.5573,6.7565 55.8173,5.3605 C57.8193,4.5025 59.3023,4.6575 60.7043,5.2055 L59.9523,7.5555 C58.8143,7.1635 57.7863,7.0655 56.7243,7.5205 C54.9353,8.2865 54.2823,10.3245 55.0623,12.1445 L55.0753,12.1745 C55.8543,13.9945 57.7623,14.9715 59.5823,14.1925 C60.7953,13.6725 61.3313,12.8695 61.8393,11.7535 L63.9683,12.6185 C63.3763,14.2535 62.4753,15.5015 60.4283,16.3785 C57.3043,17.7155 53.9523,16.2985 52.6463,13.2505" id="Fill-20"></path>
      <polygon id="Fill-22" points="64.6941 2.2886 73.1271 0.1006 73.6941 2.2886 67.7051 3.8426 68.2941 6.1096 73.5631 4.7436 74.1311 6.9316 68.8611 8.2976 69.4691 10.6456 75.5381 9.0716 76.1051 11.2596 67.5931 13.4666"></polygon>
    </g>
    }
  </svg>,
  offering: <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132 148"
    >
      <rect fill={bgFill} width="132" height="148" />
      <path
        fill={imageFill}
        d="M74.47,133.54v14.53h37.47V132.42l0-4.16h-.09C109.6,61.27,65.1,16.57,0,16.57V54.93C45.94,54.94,74.47,85.06,74.47,133.54Z"
      />
      {surveyLanguage === 'NO' &&
        <>
          <path
            fill={textFill}
            d="M35.4,41.8l-3.33-1.13.76-2.22,9.05,3.09-.75,2.22L37.8,42.62l-3,8.72-2.41-.82Z"
          />
          <path fill={textFill} d="M45.93,43.4l2.3,1.09-5,10.43L41,53.83Z" />
          <path
            fill={textFill}
            d="M53,46.92l2.15,1.36-4.93,7.81,4.87,3.08-1.24,1.95-7-4.43Z"
          />
          <path
            fill={textFill}
            d="M63.79,54.23l4,3.57a4.13,4.13,0,0,1,1.57,2.78,2.65,2.65,0,0,1-.72,2l0,0a2.7,2.7,0,0,1-2.79.85A2.83,2.83,0,0,1,65.55,67l0,0c-1.41,1.57-3.4,1.21-5.34-.52L56.1,62.84Zm-.48,8.66-2.19-2-1.69,1.89,2.25,2c.83.74,1.6.9,2.17.26l0,0C64.39,64.5,64.28,63.76,63.31,62.89Zm3.16-1.69c.51-.56.35-1.28-.45-2l-1.87-1.67-1.62,1.82,1.74,1.56c.84.75,1.61.95,2.18.31Z"
          />
          <path
            fill={textFill}
            d="M70.69,71l2.72-7.83,1.84,2.33-1.92,5,5.38-.69L80.5,72.1l-8.2.84L68.7,75.8l-1.58-2Z"
          />
          <path
            fill={textFill}
            d="M82.88,75.88l2.55,4.62A4.62,4.62,0,0,1,86,84a3.59,3.59,0,0,1-1.83,2.14l0,0a3.59,3.59,0,0,1-4.33-.44l-2.26,4.46-1.43-2.61,2-4-1-1.74L74,83.67l-1.23-2.23Zm-2.45,7.18A1.68,1.68,0,0,0,82.8,84l0,0c1-.52,1.08-1.46.48-2.54L82.1,79.21,79.19,80.8Z"
          />
        </>
      }
      {surveyLanguage === 'EN' &&
        <g id="Group" transform="translate(30.968091, 39.382546)" fill={textFill}>
          <path d="M9.40800876,7.2302539 L9.41900876,7.1992539 C10.0950088,5.3382539 9.29400876,3.2922539 7.37100876,2.5932539 C5.44900876,1.8952539 3.56300876,2.9302539 2.88700876,4.7912539 L2.87600876,4.8222539 C2.20000876,6.6832539 3.00100876,8.7292539 4.92400876,9.4272539 C6.84600876,10.1262539 8.73200876,9.0912539 9.40800876,7.2302539 M0.368008763,3.9462539 L0.379008763,3.9152539 C1.5,0.829253905 4.85300876,-0.813746095 8.20200876,0.403253905 C11.5520088,1.6202539 13.0480088,4.9892539 11.9270088,8.0752539 L11.9150088,8.1062539 C10.7950088,11.1922539 7.44200876,12.8342539 4.09300876,11.6182539 C0.743008763,10.4012539 -0.752991237,7.0312539 0.368008763,3.9462539" id="Fill-4"></path>
          <polygon id="Fill-6" points="18.5037088 4.9968539 26.1347088 9.3658539 24.9877088 11.3698539 19.5617088 8.2638539 18.3397088 10.3968539 23.1227088 13.1348539 21.9747088 15.1398539 17.1927088 12.4018539 14.9717088 16.2818539 12.7667088 15.0198539"></polygon>
          <polygon id="Fill-8" points="30.1043088 12.1521539 36.9303088 17.6941539 35.4753088 19.4871539 30.6213088 15.5461539 29.0713088 17.4551539 33.3493088 20.9281539 31.8933088 22.7211539 27.6153088 19.2481539 24.7973088 22.7191539 22.8253088 21.1171539"></polygon>
          <polygon id="Fill-10" points="40.4129088 21.1120539 46.1769088 27.6430539 44.4819088 29.1380539 40.3889088 24.5000539 38.6319088 26.0500539 42.2339088 30.1320539 40.5399088 31.6270539 36.9369088 27.5450539 35.1189088 29.1500539 39.2669088 33.8500539 37.5719088 35.3460539 31.7539088 28.7530539"></polygon>
          <path d="M47.2136088,39.1330539 C47.8666088,40.1830539 48.8026088,40.4380539 49.6286088,39.9230539 L49.6566088,39.9060539 C50.5816088,39.3310539 50.6566088,38.3910539 50.0036088,37.3390539 L48.6716088,35.1950539 L45.8556088,36.9460539 L47.2136088,39.1330539 Z M49.2766088,31.8280539 L52.0646088,36.3110539 C52.8396088,37.5580539 53.0906088,38.7420539 52.8466088,39.7880539 C52.6406088,40.6740539 52.0526088,41.4470539 51.1276088,42.0220539 L51.0996088,42.0390539 C49.5166088,43.0240539 47.9896088,42.7870539 46.7526088,41.8280539 L44.7386088,46.4020539 L43.1716088,43.8790539 L45.0036088,39.8260539 L43.9496088,38.1310539 L40.8116088,40.0820539 L39.4696088,37.9250539 L49.2766088,31.8280539 Z" id="Fill-12"></path>
          <polygon id="Fill-14" points="56.4646088 44.4045539 57.5326088 46.7095539 47.0566088 51.5675539 45.9876088 49.2625539"></polygon>
          <polygon id="Fill-16" points="59.6814088 51.5676539 60.4104088 53.7946539 55.3394088 61.1516539 62.0964088 58.9366539 62.8774088 61.3196539 51.9034088 64.9166539 51.2294088 62.8626539 56.4644088 55.2616539 49.4884088 57.5476539 48.7064088 55.1656539"></polygon>
          <path d="M58.3592088,67.0715539 L58.3912088,67.0655539 C61.6232088,66.4855539 64.7212088,68.5275539 65.3392088,71.9695539 C65.7072088,74.0165539 65.3762088,75.3495539 64.5822088,76.7165539 L62.3992088,75.4815539 C62.9722088,74.4735539 63.2552088,73.6185539 63.0192088,72.3025539 C62.6922088,70.4835539 60.8252088,69.3275539 58.8932088,69.6745539 L58.8602088,69.6795539 C56.7822088,70.0525539 55.5112088,71.7565539 55.8732088,73.7695539 C56.0362088,74.6785539 56.4092088,75.4505539 56.9772088,76.0025539 L58.6012088,75.7105539 L58.1502088,73.1935539 L60.3092088,72.8055539 L61.1952088,77.7425539 L56.2582088,78.6285539 C55.0582088,77.6365539 53.9582088,76.1755539 53.5862088,74.0955539 C52.9502088,70.5555539 54.9982088,67.6745539 58.3592088,67.0715539" id="Fill-18"></path>
        </g>
      }
    </svg>,
  income: <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 0 132 148"
  >
    <rect fill={bgFill} width="132" height="148" />
    <path
      fill={imageFill}
      d="M131.77,93.1c-46,0-74.51-30.11-74.51-78.6V0H19.82V19.8h.1c2.23,67,46.73,111.7,111.84,111.7h0V93.1Z"
    />
    {surveyLanguage === 'NO' &&
      <>
        <path fill={textFill} d="M50.11,44.16l.35,2.51L39,48.3l-.36-2.52Z" />
        <path
          fill={textFill}
          d="M51,50.44l.58,2.27L46,59.7,52.89,58l.61,2.43L42.31,63.22l-.53-2.09,5.74-7.23L40.4,55.71l-.61-2.43Z"
        />
        <path
          fill={textFill}
          d="M54.59,63.63l.95,2.14-4.3,7.84,6.5-2.89,1,2.29L48.21,77.7l-.88-2,4.43-8.1-6.7,3-1-2.29Z"
        />
        <path
          fill={textFill}
          d="M60.06,79.44l-2-2.9L60,75.22l5.41,7.89-1.93,1.32-2-2.89-7.59,5.2-1.43-2.09Z"
        />
        <path
          fill={textFill}
          d="M67.07,85l6.17,6.14-1.59,1.61-4.38-4.37-1.66,1.66,3.86,3.84-1.6,1.6L64,91.67l-1.71,1.72,4.44,4.42-1.6,1.6-6.23-6.2Z"
        />
        <path
          fill={textFill}
          d="M75.46,93l2.12,1.4L74.8,98.6,81.49,97l2.56,1.69-6.62,1.5.44,8.26-2.54-1.68-.28-6.08-1.88.42-2,3-2.12-1.4Z"
        />
        <path
          fill={textFill}
          d="M87.62,102.73l-3.24-1.37.91-2.16,8.82,3.72-.91,2.16L90,103.72l-3.58,8.48-2.34-1Z"
        />
      </>
    }
    {surveyLanguage === 'EN' &&
      <g id="Group" transform="translate(34.946300, 35.581800)" fill={textFill}>
        <polygon id="Fill-4" points="11.488 2.62900812e-13 -4.06785716e-13 1.171 0.257 3.699 11.746 2.528"></polygon>
        <polygon id="Fill-5" points="13.6389 13.9698 6.6639 15.3568 12.5829 8.6618 12.1259 6.3638 0.7979 8.6168 1.2869 11.0768 8.4889 9.6448 2.3789 16.5628 2.8009 18.6828 14.1279 16.4288"></polygon>
        <g id="Group-14" transform="translate(5.053700, 19.918200)">
          <path d="M9.8347,5.7996 C10.2227,6.8876 10.0617,7.9086 9.5987,9.0186 L11.8977,9.9156 C12.5327,8.5506 12.7787,7.0796 12.0467,5.0286 C10.8557,1.6866 7.4917,0.2586 4.3987,1.3616 L4.3677,1.3726 C1.2447,2.4866 -0.3793,5.7456 0.7627,8.9466 C1.5107,11.0446 2.6997,12.0216 4.2947,12.7146 L5.2907,10.6426 C4.2087,10.0656 3.4397,9.4806 2.9967,8.2376 C2.3327,6.3726 3.4257,4.5286 5.2907,3.8636 L5.3217,3.8526 C7.1867,3.1876 9.1807,3.9666 9.8347,5.7996" id="Fill-6"></path>
          <path d="M13.8474,22.3568 L13.8194,22.3738 C12.1004,23.3558 9.9924,22.9268 8.9764,21.1518 C7.9614,19.3758 8.6724,17.2958 10.3904,16.3138 L10.4194,16.2968 C12.1384,15.3148 14.2464,15.7428 15.2624,17.5188 C16.2774,19.2948 15.5664,21.3748 13.8474,22.3568 L13.8474,22.3568 Z M15.1944,24.6468 C18.0444,23.0158 19.0804,19.4778 17.3114,16.3848 C15.5424,13.2918 11.9224,12.3778 9.0724,14.0078 L9.0444,14.0238 C6.1944,15.6538 5.1584,19.1928 6.9274,22.2858 C8.6964,25.3788 12.3164,26.2928 15.1664,24.6628 L15.1944,24.6468 Z" id="Fill-8"></path>
          <polygon id="Fill-10" points="27.7473 30.4417 22.0993 31.5217 23.6313 25.9787 21.7743 23.9657 13.2853 31.7957 14.9743 33.6277 20.4793 28.5487 19.0713 34.2247 19.1163 34.2727 24.9463 33.3177 19.4033 38.4297 21.1153 40.2857 29.6033 32.4557"></polygon>
          <polygon id="Fill-12" points="38.0061 41.2689 39.2961 39.4139 32.1451 34.4399 25.5501 43.9199 32.7701 48.9419 34.0601 47.0859 28.9131 43.5059 30.2981 41.5149 34.7681 44.6239 36.0591 42.7689 31.5891 39.6599 32.9271 37.7369"></polygon>
        </g>
      </g>
    }
  </svg>,
  operations: <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132 148"
  >
    <rect fill={bgFill} x="0.23" width="132" height="148" />
    <path
      fill={imageFill}
      d="M74.47,0V14.5C74.47,63,45.94,93.1,0,93.1v38.4c66.93,0,111.94-47,111.94-117V0Z"
    />
    {surveyLanguage === 'NO' &&
      <>
        <path
          fill={textFill}
          d="M30.21,115.05l1.09-2.14a2.85,2.85,0,0,0,2.34.57c.92-.25,1.35-1,1-2.25L32.72,104l2.51-.67,2,7.3a4.21,4.21,0,0,1-.26,3.26,4.09,4.09,0,0,1-2.62,1.81A4.72,4.72,0,0,1,30.21,115.05Z"
        />
        <path
          fill={textFill}
          d="M40.33,108l0,0a6.14,6.14,0,0,1,11.12-5.2v0A6.14,6.14,0,0,1,40.33,108Zm8.72-4,0,0a3.5,3.5,0,0,0-4.7-1.82,3.45,3.45,0,0,0-1.62,4.72v0a3.51,3.51,0,0,0,4.7,1.82A3.45,3.45,0,0,0,49.05,103.9Z"
        />
        <path
          fill={textFill}
          d="M51.27,96l4.27-3.24a4.19,4.19,0,0,1,3-1,2.7,2.7,0,0,1,1.82,1.09l0,0a2.71,2.71,0,0,1,.3,2.91,2.81,2.81,0,0,1,3.43,1l0,0c1.28,1.68.55,3.56-1.52,5.13l-4.38,3.33Zm7.36-1.3c-.46-.61-1.19-.59-2.05.06l-2,1.52,1.48,1.94,1.87-1.42c.89-.67,1.24-1.39.72-2.08Zm1,3.43-2.34,1.77,1.54,2,2.4-1.83c.89-.68,1.19-1.4.67-2.09l0,0C61.47,97.35,60.72,97.32,59.68,98.11Z"
        />
        <path
          fill={textFill}
          d="M60.93,88.22l3.51-4a4.12,4.12,0,0,1,2.75-1.61,2.65,2.65,0,0,1,2,.69l0,0a2.67,2.67,0,0,1,.88,2.78,2.82,2.82,0,0,1,3.56.22l0,0c1.59,1.38,1.27,3.37-.44,5.34l-3.6,4.15Zm6.93-2.79c-.57-.49-1.28-.33-2,.48l-1.64,1.9,1.84,1.6,1.54-1.77c.73-.85.92-1.62.28-2.18Zm1.73,3.14-1.92,2.22,1.92,1.67,2-2.28c.73-.85.88-1.62.23-2.18l0,0C71.19,87.47,70.45,87.59,69.59,88.57Z"
        />
        <path
          fill={textFill}
          d="M68.8,78.57,73.17,71l2,1.13L72,77.52l2,1.17L76.78,74l2,1.13L76,79.82,78.11,81l3.14-5.43,2,1.14-4.4,7.61Z"
        />
        <path
          fill={textFill}
          d="M74.43,68.31l1.84-5a4.54,4.54,0,0,1,2.27-2.74,3.62,3.62,0,0,1,2.82,0h0a3.58,3.58,0,0,1,2.49,3.57L88.73,63l-1,2.78-4.33,1-.7,1.87L86.14,70l-.89,2.38Zm7-2.84a1.68,1.68,0,0,0-.85-2.39h0c-1-.38-1.82.13-2.25,1.29l-.88,2.37,3.11,1.16Z"
        />
      </>
    }
    {surveyLanguage === 'EN' &&
      <g id="Group" transform="translate(23.469434, 22.248291)" fill={textFill}>
        <path d="M9.55466613,84.3024095 L9.54766613,84.2704095 C9.14866613,82.3304095 7.39466613,81.0074095 5.39066613,81.4194095 C3.38666613,81.8314095 2.32266613,83.7004095 2.72166613,85.6404095 L2.72766613,85.6724095 C3.12666613,87.6124095 4.88166613,88.9354095 6.88566613,88.5234095 C8.88866613,88.1124095 9.95266613,86.2414095 9.55466613,84.3024095 M0.132666129,86.2394095 L0.125666129,86.2074095 C-0.535333871,82.9914095 1.46066613,79.8354095 4.95066613,79.1174095 C8.44166613,78.4004095 11.4816661,80.4874095 12.1426661,83.7024095 L12.1496661,83.7354095 C12.8106661,86.9504095 10.8156661,90.1074095 7.32466613,90.8244095 C3.83366613,91.5424095 0.793666129,89.4554095 0.132666129,86.2394095" id="Fill-4"></path>
        <path d="M19.4667661,81.0934095 C20.6517661,80.6354095 21.0707661,79.6594095 20.7137661,78.7374095 L20.7017661,78.7054095 C20.2917661,77.6444095 19.3327661,77.3614095 18.1477661,77.8194095 L16.3167661,78.5264095 L17.5897661,81.8194095 L19.4667661,81.0934095 Z M13.1207661,77.3034095 L17.5207661,75.6024095 C20.0907661,74.6094095 22.2337661,75.5314095 23.0847661,77.7324095 L23.0957661,77.7634095 C24.0597661,80.2554095 22.6207661,82.2974095 20.2047661,83.2314095 L18.4047661,83.9274095 L19.6537661,87.1584095 L17.2837661,88.0744095 L13.1207661,77.3034095 Z" id="Fill-6"></path>
        <polygon id="Fill-8" points="23.3246661 73.1285095 30.8276661 68.7035095 31.9756661 70.6505095 26.6466661 73.7935095 27.8376661 75.8115095 32.5266661 73.0455095 33.6746661 74.9925095 28.9856661 77.7585095 30.2176661 79.8475095 35.6176661 76.6625095 36.7656661 78.6095095 29.1916661 83.0765095"></polygon>
        <path d="M40.2260661,68.2682095 C41.1620661,67.4572095 41.2660661,66.4952095 40.6290661,65.7592095 L40.6070661,65.7342095 C39.8940661,64.9112095 38.9540661,64.9832095 38.0180661,65.7922095 L36.1100661,67.4452095 L38.2800661,69.9522095 L40.2260661,68.2682095 Z M32.6880661,67.3732095 L36.6800661,63.9182095 C37.7900661,62.9572095 38.9200661,62.5252095 39.9910661,62.6022095 C40.8980661,62.6672095 41.7540661,63.1262095 42.4670661,63.9502095 L42.4880661,63.9742095 C43.7080661,65.3842095 43.7140661,66.9282095 42.9600661,68.3012095 L47.7920661,69.5732095 L45.5470661,71.5162095 L41.2580661,70.3422095 L39.7480661,71.6482095 L42.1670661,74.4422095 L40.2470661,76.1052095 L32.6880661,67.3732095 Z" id="Fill-10"></path>
        <path d="M50.8481661,58.3805095 L46.9511661,57.2885095 L48.9611661,60.8015095 L50.8481661,58.3805095 Z M43.8431661,56.3105095 L45.2841661,54.4625095 L57.5001661,57.7085095 L55.8681661,59.8035095 L53.1751661,59.0445095 L50.1731661,62.8965095 L51.5681661,65.3215095 L49.9751661,67.3645095 L43.8431661,56.3105095 Z" id="Fill-12"></path>
        <polygon id="Fill-14" points="51.7895661 49.0099095 50.0825661 52.0809095 48.0345661 50.9429095 52.6855661 42.5799095 54.7325661 43.7179095 53.0245661 46.7889095 61.0695661 51.2629095 59.8355661 53.4839095"></polygon>
        <polygon id="Fill-16" points="53.4985661 40.4288095 54.4765661 38.0828095 65.1365661 42.5228095 64.1595661 44.8688095"></polygon>
        <path d="M63.7690661,28.0577095 L63.7380661,28.0487095 C61.8320661,27.5147095 59.8510661,28.4677095 59.2980661,30.4377095 C58.7470661,32.4077095 59.9200661,34.2097095 61.8280661,34.7437095 L61.8590661,34.7527095 C63.7650661,35.2867095 65.7460661,34.3337095 66.2980661,32.3637095 C66.8500661,30.3937095 65.6750661,28.5917095 63.7690661,28.0577095 M61.1740661,37.3197095 L61.1420661,37.3107095 C57.9810661,36.4247095 56.0900661,33.2047095 57.0510661,29.7737095 C58.0130661,26.3417095 61.2610661,24.5967095 64.4220661,25.4817095 L64.4550661,25.4907095 C67.6160661,26.3767095 69.5060661,29.5967095 68.5440661,33.0277095 C67.5830661,36.4597095 64.3350661,38.2047095 61.1740661,37.3197095" id="Fill-18"></path>
        <polygon id="Fill-20" points="59.2709661 21.5919095 59.5769661 19.2699095 67.3339661 14.8329095 60.2839661 13.9039095 60.6119661 11.4179095 72.0619661 12.9259095 71.7799661 15.0689095 63.7699661 19.6549095 71.0489661 20.6139095 70.7209661 23.1009095"></polygon>
        <path d="M70.7914661,9.05920947 L68.9304661,7.63820947 C69.7414661,6.56220947 70.2374661,5.45120947 70.1804661,4.13220947 C70.1354661,3.09420947 69.6954661,2.48520947 69.0204661,2.51520947 L68.9864661,2.51720947 C68.3444661,2.54420947 68.0314661,2.95420947 67.6214661,4.90420947 C67.1294661,7.25420947 66.5524661,8.78220947 64.2614661,8.88120947 L64.2294661,8.88320947 C62.1354661,8.97420947 60.6784661,7.35320947 60.5744661,4.99720947 C60.5014661,3.31620947 60.9664661,1.85920947 61.8544661,0.648209465 L63.8244661,1.88320947 C63.1604661,2.93520947 62.7754661,3.95920947 62.8174661,4.93220947 C62.8604661,5.90420947 63.3274661,6.39620947 63.8884661,6.37120947 L63.9214661,6.37020947 C64.6794661,6.33720947 64.9044661,5.83220947 65.3284661,3.81520947 C65.8364661,1.44820947 66.6194661,0.0932094654 68.6304661,0.00620946537 L68.6624661,0.00420946537 C70.9534661,-0.0957905346 72.3154661,1.59520947 72.4244661,4.08420947 C72.5004661,5.83120947 71.9674661,7.62120947 70.7914661,9.05920947" id="Fill-22"></path>
      </g>
    }
  </svg>,
}

  return svgs[svg];
};
