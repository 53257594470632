import * as utill from "../utill";
import * as actionTypes from "./actions";

const initialState = {
  loadingSurvey: false,
  loadingQuestion: true,
  loadingSubmission: false,
  survey: null,
  startPageContent: null,
  settings: null,
  error: null,
  errorMessage: null,
  userData: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    company: "",
    title: "",
    address: "",
    marketingPermission: false,
    newsletterSubscription: false,
  },
  submission: null,
  formSubmitted: false,
  progress: {
    section: 0,
    question: 0,
    totalSlides: null,
    currentSlide: null,
    progressCount: null,
    progressProcentage: null,
  },
  currentQuestionState: {
    questionSubmissionId: null,
    answers: null,
  },
  currentBackgroundImage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.error.message,
      };

    case actionTypes.GET_QUESTION:
      const question =
        state.survey.sections[action.activeSection].questions[
          action.activeQuestion
        ];
      const progress = utill.updateProgress(
        state.survey,
        action.activeSection,
        action.activeQuestion
      );

      // Map the existing answers.
      let existingAnswers = {};
      let questionSubmission = null;

      if (state.submission && state.submission.questionSubmissions) {
        questionSubmission = state.submission.questionSubmissions.find(
          questionSubmission => questionSubmission.questionId === question.id
        );
        if (questionSubmission) {
          for (let answerOptionSubmission of questionSubmission.answerOptionSubmissions) {
            existingAnswers[answerOptionSubmission.answerOption] =
              answerOptionSubmission.selected;
          }
        }
      }

      //initialize answer array
      let selectedAnswers = [];
      for (let answerOption of question.answerOptions) {
        selectedAnswers.push({
          answerOptionId: answerOption.id,
          isSelected: existingAnswers[answerOption.id] || false,
        });
      }

      return {
        ...state,
        question: question,
        currentQuestionState: {
          answers: selectedAnswers,
          questionSubmission: questionSubmission,
          textAnswer: "", // FIXME
        },
        buttonLabel: state.survey.settings.surveyNextButton,
        progress: progress,
        loadingQuestion: false,
      };

    case actionTypes.SET_TEXT_ANSWER:
      return {
        ...state,
        currentQuestionState: {
          ...state.currentQuestionState,
          textAnswer: action.value,
        },
      };

    case actionTypes.SET_OPTION_ANSWER:
      const answerList = [...state.currentQuestionState.answers];
      const answer = action.answer;

      for (let i = 0; i < answerList.length; i++) {
        if (answerList[i].answerOptionId === answer.answerOptionId) {
          answerList[i].isSelected = answer.isSelected;
        }
      }

      return {
        ...state,
        currentQuestionState: {
          ...state.currentQuestionState,
          answers: answerList,
        },
      };

    case actionTypes.GET_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.survey.settings,
        },
      };

    case actionTypes.UPDATE_FORMDATA:
      return {
        ...state,
        userData: action.data.userData,
        submission: action.data.submissionData,
        formSubmitted: true,
      };

    case actionTypes.UPDATE_SUBMISSION:
      return {
        ...state,
        submission: action.data,
      };

    case actionTypes.UPDATE_ANSWER_OPTION_SUBMISSIONS:
      let submission = state.submission;
      const questionSubmissionIndex = submission.questionSubmissions.findIndex(
        questionSubmission =>
          questionSubmission.id === action.data.questionSubmissionId
      );
      submission.questionSubmissions[
        questionSubmissionIndex
      ].answerOptionSubmissions = action.data.answerOptionSubmissions;
      return {
        ...state,
        submission: submission,
      };

    case actionTypes.RECEIVE_SURVEY:
      // TODO Progress isn't working; this is the function the old survey setter was using:
      // const surveyStatus = utill.updateProgress(action.survey, 0, 0);
      return {
        ...state,
        loadingSurvey: false,
        survey: action.survey,
      };

    case actionTypes.REQUEST_SURVEY:
      return {
        ...state,
        error: null,
        errorMessage: null,
        loadingSurvey: true,
      };

    case actionTypes.RECEIVE_SUBMISSION:
      return {
        ...state,
        loadingSubmission: false,
        submission: action.submission,
      };

    case actionTypes.REQUEST_SUBMISSION:
      return {
        ...state,
        error: null,
        errorMessage: null,
        loadingSubmission: true,
      };

    case actionTypes.SET_LEAD_FORM:
      return {
        ...state,
        leadForm: action.leadForm,
      };

    case actionTypes.SET_CURRENT_BACKGROUND_IMAGE:
      return {
        ...state,
        currentBackgroundImage: action.currentBackgroundImage,
      };

    default:
      return state;
  }
};

export default reducer;
