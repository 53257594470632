import {createMuiTheme} from '@material-ui/core/styles';

// Create custom Material Theme
const theme = createMuiTheme({
  overrides: {
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  palette:{
    primary: {
      main: "#3C3D48",
      inverted: "#FFFFFF",
    },
    secondary: {
      main: "#FFE300",
    },
    background: {
      default: "#FFFFFF",
      active: "#3C3D48",
      light: "#EEEEEE",
      dark: "#878787",
    },
    button:{
      main: "#3C3D48",
    },
    icon:{
      secondary: "#3C3D48",
      default: "#878787",
      light: "#EEEEEE",
    },
    progressBar: {
      light:"#D8D8D8",
      dark: "#717171",
    },
    model: {
      green: "#78B571",
      yellow: "#FFE300",
      red: "#DA442B",
      grey: "#878787",
    },
  },
  typography: {
    fontFamily: [
      '"Gotham Book"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1:{
      fontFamily: '"Gotham Medium"',
      fontSize: "40px",
      lineHeight: "63px",
    },
    h2:{
      fontFamily: [
        '"PT Serif"',
        'serif',
      ].join(','),
      fontWeight: 400,
      fontSize: "30px",
      lineHeight: "40px",
    },
    h3:{
      fontFamily: [
        '"PT Serif"',
        'serif',
      ].join(','),
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "40px",
    },
    h4:{
      fontFamily: '"Gotham Bold"',
      fontSize: "14px",
      textTransform: "uppercase",
      lineHeight: "24px",
    },
    body1:{
      fontFamily: '"Gotham Book"',
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2:{
      fontFamily: '"Gotham Book"',
      fontSize: "14px",
      lineHeight: "24px",
    },
    caption:{
      fontFamily: '"Gotham Book"',
      fontSize: "12px",
      lineHeight: "16px",
    },
    button:{
      fontFamily: '"Gotham Medium"',
      fontSize: "16px",
      textTransform: "uppercase",
      lineHeight: "24px",
    },
  },
  spacing: 8,
})

export default theme;