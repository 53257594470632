import React, { Component } from "react";
import PropTypes from "prop-types";

import axios from "../../../axios";
import styles from "./Input.module.css";
export default class CompanyInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedCompanies: [],
      showAutoComplete: false,
    };
    this.timer = null;
  }

  delayFetch = (fn, delay) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(fn, delay);
  };

  fetchCompanies = company => {
    if (this.props.value.length > 2) {
      axios.get("/companies", { params: { q: company } }).then(response => {
        this.setState({ fetchedCompanies: response.data });
      });
      this.setState({ showAutoComplete: true });
    } else {
      this.setState({ showAutoComplete: false });
    }
  };

  inputChangeHandler = event => {
    this.props.onChange(event);
    this.delayFetch(() => {
      this.fetchCompanies(this.props.value);
    }, 500);
  };

  onCompanySelect = company => {
    this.props.companySelectHandler(company);
    this.setState({ showAutoComplete: false, fetchedCompanies: [] });
  };

  handleFocusChange = (hasFocus) => {
    this.setState({ showAutoComplete: hasFocus });
  };

  render() {
    let companyList = null;
    if (this.state.fetchedCompanies && this.state.showAutoComplete) {
      if (this.state.fetchedCompanies.length > 0) {
        companyList = (
          <div className={styles.Suggestions}>
            {this.state.fetchedCompanies.map(company => (
              <div
                className={styles.Suggestion}
                key={company.companyId}
                onMouseDown={event => event.preventDefault()}
                onMouseUp={() => this.onCompanySelect(company)}
              >
                {company.name}
              </div>
            ))}
          </div>
        );
      }
    }

    let invalidClass = null;

    if (this.props.isValid !== this.props.touched) {
      invalidClass = styles.Invalid;
    }

    return (
      <div className={styles.inputContainer}>
        <input
          type="text"
          autoComplete="off"
          name={this.props.name}
          className={[styles.InputElement, invalidClass].join(" ")}
          onChange={this.inputChangeHandler}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onFocus={() => this.handleFocusChange(true)}
          onBlur={() => this.handleFocusChange(false)}
        />
        {companyList}
      </div>
    );
  }
}

CompanyInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onCompanySelect: PropTypes.func,
};
