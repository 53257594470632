import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Link } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles(theme => ({
  displayTextLink: {
    fontWeight: 'bold',
  },
  modal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    maxWidth: "800px",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    minHeight: 384,
    outline: 0,
    overflowY: 'auto',
    padding: theme.spacing(2),
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      minHeight: "unset",
    },
    width: '100%',
  },
  content: {
    padding: `${theme.spacing(2, 6)} !important`,
    '& p': {
      color: theme.palette.primary.main,
      margin:  theme.spacing(0, 0, 2, 0),
      ...theme.typography.body1,
    },
  },
  closeIconGrid: {
    textAlign: 'right',
  },
  closeModal: {
    color: theme.palette.icon.default,
    cursor: "pointer",
    verticalAlign: 'top',
  },
}));

export default function Dialog(props) {
  const { displayText, children } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogOpen = (e) =>{
    e.preventDefault();
    setIsOpen(true)
  };

  const handleDialogClose = () =>{
    setIsOpen(false);
  };

  return (
    <>
      <Link
        id="modal-opener"
        href="#"
        variant="body1"
        onClick={handleDialogOpen}
        className={classes.displayTextLink}
      >
        {displayText}
      </Link>
      <Modal
        aria-labelledby="modal-opener"
        aria-describedby="modal-header"
        className={classes.modal}
        open={isOpen}
        onClose={handleDialogClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.closeIconGrid}>
              <HighlightOffIcon
                  onClick={handleDialogClose}
                  className={classes.closeModal}
                />
              </Grid>
              <Grid container item xs={12} className={classes.content}>
                {children}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

Dialog.propTypes = {
  displayText: PropTypes.string,
};
