import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import theme from "../../../theme";
import { ModelSVG } from "./ModelSVG";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {},
  modelContainer: {
    display: "grid",
    gridTemplateColumns: "135px 135px",
    gridTemplateRows: "148px 148px",
    gridGap: "0px",
  },
  item: {
    flexGrow: 0,
  },
  legendContainer: {
    whiteSpace: "nowrap",
  },
  colorLegend: {
    display: "flex",
    alignItems: "center",
  },
  playArrow: {
    fontSize: "33px",
  },
  green: {
    color: theme.palette.model.green,
  },
  yellow: {
    color: theme.palette.model.yellow,
  },
  red: {
    color: theme.palette.model.red,
  },
  grey: {
    color: theme.palette.model.grey,
  },
}));

const COLOR_TO_TEXT = {
  NO: {
    green: "Moderat",
    yellow: "Viktig",
    red: "Svært viktig",
    grey: "Ikke relevant",
  },
  EN: {
    green: "Moderately important",
    yellow: "Important",
    red: "Very important",
    grey: "Not relevant",
  },
};

const Model = (props) => {
  // TODO Take only the colors as the prop, not the result(s)
  // TODO Or just take the results from the state
  const classes = useStyles();

  const { surveyLanguage } = props;

  let sectionColors = [].fill("grey", 0, 4);
  if (props.sectionResult) {
    sectionColors[props.sectionResult.index] = props.sectionResult.color;
  } else {
    for (let result of props.resultsBySection) {
      sectionColors[result.index] = result.color;
    }
  }

  return (
    <Grid container spacing={7} justify="center" alignItems="center">
      <Grid item sm classes={{ item: classes.item }}>
        <div className={classes.modelContainer}>
          <div>
            <ModelSVG
              svg="experience"
              surveyLanguage={surveyLanguage}
              imageFill={theme.palette.model[sectionColors[0]]}
              textFill={sectionColors[0] === 'yellow' ? 'black' : undefined}
            />
          </div>
          <div>
            <ModelSVG
              svg="offering"
              surveyLanguage={surveyLanguage}
              imageFill={theme.palette.model[sectionColors[1]]}
              textFill={sectionColors[1] === 'yellow' ? 'black' : undefined}
            />
          </div>
          <div>
            <ModelSVG
              svg="income"
              surveyLanguage={surveyLanguage}
              imageFill={theme.palette.model[sectionColors[2]]}
              textFill={sectionColors[2] === 'yellow' ? 'black' : undefined}
            />
          </div>
          <div>
            <ModelSVG
              svg="operations"
              surveyLanguage={surveyLanguage}
              imageFill={theme.palette.model[sectionColors[3]]}
              textFill={sectionColors[3] === 'yellow' ? 'black' : undefined}
            />
          </div>
        </div>
      </Grid>

      <Grid
        item
        sm
        className={classes.legendContainer}
        classes={{ item: classes.item }}
      >
        {props.result ? (
          <div className={classes.colorLegend}>
            <PlayArrowIcon
              style={{
                color: `${theme.palette.model[props.result.color]}`,
              }}
              className={classes.playArrow}
            />
            <Typography variant="h4" color="primary" display="inline">
              {COLOR_TO_TEXT[surveyLanguage][props.result.color]}
            </Typography>
          </div>
        ) : (
          <>
            <div className={classes.colorLegend}>
              <PlayArrowIcon
                className={[classes.green, classes.playArrow].join(" ")}
              />
              <Typography variant="h4" color="primary" display="inline">
                {COLOR_TO_TEXT[surveyLanguage].green}
              </Typography>
            </div>
            <div className={classes.colorLegend}>
              <PlayArrowIcon
                className={[classes.yellow, classes.playArrow].join(" ")}
              />
              <Typography variant="h4" color="primary" display="inline">
                {COLOR_TO_TEXT[surveyLanguage].yellow}
              </Typography>
            </div>
            <div className={classes.colorLegend}>
              <PlayArrowIcon
                className={[classes.red, classes.playArrow].join(" ")}
              />
              <Typography variant="h4" color="primary" display="inline">
                {COLOR_TO_TEXT[surveyLanguage].red}
              </Typography>
            </div>
            <div className={classes.colorLegend}>
              <PlayArrowIcon
                className={[classes.grey, classes.playArrow].join(" ")}
              />
              <Typography variant="h4" color="primary" display="inline">
                {COLOR_TO_TEXT[surveyLanguage].grey}
              </Typography>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
}

Model.propTypes = {
  resultsBySection: PropTypes.array,
  sectionResult: PropTypes.object,
};


export default Model;
