import React from "react";
import classnames from 'classnames';
import { makeStyles, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.primary.main,
    '& p': {
      margin:  theme.spacing(0, 0, 2, 0),
      ...theme.typography.body1,
    },
    '& > div, & > footer': {
      margin: 'auto',
      maxWidth: 656,
      width: 'unset',
      [theme.breakpoints.down("lg")]: {
        margin: theme.spacing(0, 8),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0, 5),
      },
    }
  },
  dialog: {
    background: theme.palette.secondary.main,
  },
  dialogContainer: {
    padding: theme.spacing(5, 0),
  },
  websiteGrid: {
    textAlign: 'right',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0.5, 0, 2),
  },
}));

const footerText = {
  NO:  <>Onsagers IP-sjekk&trade;</>,
  EN: 'Onsagers IP-check',
};

const Footer = ({ children, surveyLanguage, hasDialog }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.container, {
        [classes.dialog]: hasDialog,
      })}
    >
    {children &&
      <Grid container className={classes.dialogContainer}>
        {children}
      </Grid>
    }
    <footer className={classes.footer}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body2">{footerText[surveyLanguage]}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.websiteGrid}>
          <Typography variant="body2">www.onsagers.no</Typography>
        </Grid>
      </Grid>
    </footer>
    </div>
  );
};
export default Footer;
