const getQuestionCount = (section) => {
    return section.questions.length;
}

export const updateProgress = (survey, aSection, aQuestion) => {

    let progress = {
        section : aSection,
        question : aQuestion,
        totalQuestions : null,
        currentQuestion : null,
        total : null,
        totalProcentage : null,
    }

    let currentQuestion = 0;

    for(let i = 0; i < survey.sections.length; i++){

        let sectionQuestionCount = getQuestionCount(survey.sections[i])
        progress.totalQuestions += sectionQuestionCount;

        if(i < aSection){
            currentQuestion += sectionQuestionCount;
        }else if(i === aSection){
            //if a question excist add it to count
            if(survey.sections[i].questions[aQuestion]){
                currentQuestion += (aQuestion + 1);
            }
            
        }
        progress.currentQuestion = currentQuestion;
    }
    progress.total = progress.currentQuestion + "/" + progress.totalQuestions;
    progress.totalProcentage = (progress.currentQuestion / progress.totalQuestions * 100).toFixed(2);

    return progress
}