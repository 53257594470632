import axios from "../axios";

export const GET_QUESTION = "GET_QUESTION";
export const SET_OPTION_ANSWER = "SET_OPTION_ANSWER";
export const SET_TEXT_ANSWER = "SET_TEXT_ANSWER";
export const GET_SETTINGS = "GET_SETTINGS";
export const SET_ERROR = "SET_ERROR";
export const UPDATE_FORMDATA = "UPDATE_FORMDATA";
export const UPDATE_SUBMISSION = "UPDATE_SUBMISSION";
export const UPDATE_ANSWER_OPTION_SUBMISSIONS =
  "UPDATE_ANSWER_OPTION_SUBMISSIONS";
export const RECEIVE_SURVEY = "RECEIVE_SURVEY";
export const REQUEST_SURVEY = "REQUEST_SURVEY";
export const RECEIVE_SUBMISSION = "RECEIVE_SUBMISSION";
export const REQUEST_SUBMISSION = "REQUEST_SUBMISSION";
export const SET_LEAD_FORM = "SET_LEAD_FORM";
export const SET_CURRENT_BACKGROUND_IMAGE = "SET_CURRENT_BACKGROUND_IMAGE";

export const setError = error => {
  return {
    type: SET_ERROR,
    error: error,
  };
};

const requestSurvey = () => {
  return {
    type: REQUEST_SURVEY,
  };
};

const receiveSurvey = survey => {
  return {
    type: RECEIVE_SURVEY,
    survey,
  };
};

const shouldFetchSurvey = (state, surveyId) => {
  const survey = state.survey;
  const isLoading = state.loadingSurvey;

  if (isLoading) {
    return false;
  }
  if (survey && survey.id === surveyId) {
    return false;
  }

  return true;
};

export const fetchSurveyIfNeeded = surveyId => {
  return (dispatch, getState) => {
    if (shouldFetchSurvey(getState(), surveyId)) {
      dispatch(getSurvey(surveyId));
    }
  };
};

const requestSubmission = () => {
  return {
    type: REQUEST_SUBMISSION,
  };
};

const receiveSubmission = submission => {
  return {
    type: RECEIVE_SUBMISSION,
    submission,
  };
};

const shouldFetchSubmission = (state, submissionId) => {
  const submission = state.submission;
  const isLoading = state.loadingSubmission;

  if (isLoading) {
    return false;
  }
  if (submission && submission.id === submissionId) {
    return false;
  }

  return true;
};

const fetchSubmission = id => {
  return dispatch => {
    console.debug("Fetching submission...");
    dispatch(requestSubmission());
    axios
      .get(`submission/${id}`)
      .then(response => {
        console.debug("Received submission: ", response.data);
        dispatch(receiveSubmission(response.data));
      })
      .catch(error => {
        // TODO add error handling
        dispatch(receiveSubmission(null));
      });
  };
};

export const fetchSubmissionIfNeeded = submissionId => {
  return (dispatch, getState) => {
    if (shouldFetchSubmission(getState(), submissionId)) {
      dispatch(fetchSubmission(submissionId));
    }
  };
};

export const getSurvey = surveyId => {
  return dispatch => {
    console.debug("Fetching survey...");
    dispatch(requestSurvey());
    //get survey
    axios
      .get("/survey/" + surveyId)
      .then(response => {
        const survey = response.data;
        console.debug("Received survey: ", survey);
        dispatch(receiveSurvey(survey));
      })
      .catch(error => {
        // TODO Set error to "Survey not found"
        dispatch(setError(error));
      });
  };
};

export const setLeadFormToState = leadForm => {
  return {
    type: SET_LEAD_FORM,
    leadForm,
  };
};

export const getLeadForm = lang => {
  return dispatch => {
    axios
      .get("/leadForm", { params: { lang } }) //Will return a list
      .then(response => {
        dispatch(setLeadFormToState(response.data[0]));
      });
  };
};

export const getQuestion = (section, question) => {
  return {
    type: GET_QUESTION,
    activeSection: section,
    activeQuestion: question,
  };
};

export const setTextAnswer = value => {
  return {
    type: SET_TEXT_ANSWER,
    value: value,
  };
};

export const setOptionAnswer = answer => {
  return {
    type: SET_OPTION_ANSWER,
    answer: answer,
  };
};

export const getSettings = () => {
  return {
    type: GET_SETTINGS,
  };
};

export const updateFormData = (userData, submissionData) => {
  return {
    type: UPDATE_FORMDATA,
    data: {
      userData: userData,
      submissionData: submissionData,
    },
  };
};

export const updateSubmissionData = data => {
  return {
    type: UPDATE_SUBMISSION,
    data: data,
  };
};

export const updateAnswerOptionSubmissions = (
  questionSubmissionId,
  answerOptionSubmissions
) => {
  return {
    type: UPDATE_ANSWER_OPTION_SUBMISSIONS,
    data: {
      questionSubmissionId,
      answerOptionSubmissions,
    },
  };
};

export const setCurrentBackgroundImage = currentBackgroundImage => {
  return {
    type: SET_CURRENT_BACKGROUND_IMAGE,
    currentBackgroundImage: currentBackgroundImage,
  };
};
