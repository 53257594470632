import React, { Component } from "react";
import Option from "./Option/Option";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import Slide from "../../../components/Slides/Slide";
import Loader from "../../../components/UI/Loader/Loader";
import ErrorHandler from "../../../components/UI/ErrorHandler/ErrorHandler";
import SectionHeader from "./../../../components/UI/SectionHeader/SectionHeader";
import Navigation from "./../../../components/UI/Navigation/Navigation";

import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";

import { withStyles, Typography } from "@material-ui/core";
import Footer from "../../../components/UI/Footer/Footer";

const styles = (theme) => ({
  questionContentTypography: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3, 0, 2),
    },
  },
});

class QuestionSlide extends Component {
  // create the scroll ref
  scrollToTopRef = React.createRef();

  componentDidMount() {
    const section =
      this.props.match.params.sectionNumber - 1 >= 0
        ? this.props.match.params.sectionNumber - 1
        : 0;
    const question =
      this.props.match.params.questionNumber - 1 >= 0
        ? this.props.match.params.questionNumber - 1
        : 0;

    this.props.getQuestion(section, question);

    this.props.setCurrentBackgroundImage(
      this.props.survey.sections[0].backgroundImage
    );
  }

  componentDidUpdate(prevProps) {
    const questionNumber = this.props.match.params.questionNumber;
    const sectionNumber = this.props.match.params.sectionNumber;
    const questionHasChanged =
      prevProps.match.params.questionNumber !== questionNumber;
    const sectionHasChanged =
      prevProps.match.params.sectionNumber !== sectionNumber;

    // TODO This is horribly error-prone.
    if (questionHasChanged || sectionHasChanged) {
      let section = sectionNumber - 1;
      if (section < 0) {
        section = 0;
      }

      let question = questionNumber - 1;
      if (question < 0) {
        question = 0;
      }

      this.props.getQuestion(section, question);
      this.props.setCurrentBackgroundImage(
        this.props.survey.sections[section].backgroundImage
      );
    }
  }

  submitAnswerHandler = () => {
    let answerOptions = [];
    for (let answer of this.props.answers) {
      let answerOptionData = {
        answerOption: answer.answerOptionId,
        selected: answer.isSelected,
      };
      // If a question submission exists then this question has already been answered.
      // Add the answerOptionSubmission IDs to the answer option data (so we can update them.)
      if (this.props.questionSubmission) {
        const answerOptionSubmission = this.props.questionSubmission.answerOptionSubmissions.find(
          (answerOptionSubmission) =>
            answerOptionSubmission.answerOption === answer.answerOptionId
        );
        answerOptionData.id = answerOptionSubmission.id;
      }
      answerOptions.push(answerOptionData);
    }

    if (this.props.questionSubmission) {
      this.updateAnswerOptionSubmissions(answerOptions);
    } else {
      this.createQuestionSubmission(answerOptions);
    }
  };

  createQuestionSubmission = (answerOptionData) => {
    const questionSubmissionData = {
      sectionId: this.getSection().id,
      questionId: this.props.question.id,
      answerFreeText: "", // FIXME
      submission: this.props.submission.id,
      answerOptionSubmissions: answerOptionData,
    };

    axios
      .post(`/questionSubmission`, questionSubmissionData)
      .then((response) => {
        console.debug("questionSubmission successful!", response.data);
        const questionSubmission = response.data;
        let updatedSubmissionData = {
          ...this.props.submission,
        };
        updatedSubmissionData.questionSubmissions.push(questionSubmission);
        this.props.updateSubmissionData(updatedSubmissionData);

        //if user is submitting last question
        if (
          this.props.progress.currentQuestion ===
          this.props.progress.totalQuestions
        ) {
          if (process.env.NODE_ENV === "development") {
            console.warn(
              "App is running in development environment; updated lead data was not sent to Pardot."
            );
            this.navigateToNextPage();
          } else {
            axios
              .post(`/submission/${this.props.submission.id}/finish`)
              .then(() => this.navigateToNextPage());
          }
        } else {
          this.navigateToNextPage();
        }
      })
      .catch((error) => {
        this.props.setError(error);
      });
  };

  updateAnswerOptionSubmissions = (answerOptionData) => {
    axios
      .put(`/answerOptionSubmission/bulk_update`, answerOptionData)
      .then((response) => {
        console.debug(
          "answer option submission put successful!",
          response.data
        );
        this.props.updateAnswerOptionSubmissions(
          this.props.questionSubmission.id,
          response.data
        );
        this.navigateToNextPage();
      })
      .catch((error) => {
        this.props.setError(error);
      });
  };

  navigateToNextPage = () => {
    let {
      history,
      survey: { sections },
      progress: { currentQuestion, totalQuestions },
      submission: { id: submissionId },
      match: { params: { id: surveyId, questionNumber, sectionNumber } },
    } = this.props;

    questionNumber = parseInt(questionNumber);
    sectionNumber = parseInt(sectionNumber);

    questionNumber += 1;

    if (questionNumber > sections[sectionNumber - 1].questions.length) {
      questionNumber = 1;
      sectionNumber += 1;
    }

    let nextPage =  `section/${sectionNumber}/question/${questionNumber}`;

    if (currentQuestion === totalQuestions) {
      nextPage = `results/${submissionId}`;
    }

    history.push(`/survey/${surveyId}/${nextPage}`);

    //  scroll to the top of the options container on transition to the next question
    if (this.scrollToTopRef.current) {
      this.scrollToTopRef.current.scrollIntoView();
    }
  };

  navigateToPreviousPage = () => {
    let {
      history,
      survey: { sections },
      match: { params: { id: surveyId, questionNumber, sectionNumber } },
    } = this.props;

    questionNumber -= 1;

    if (questionNumber === 0) {
      sectionNumber -= 1;
      questionNumber = sections[sectionNumber - 1].questions.length;
    }

    const previousPage = `section/${sectionNumber}/question/${questionNumber}`;

    history.push(`/survey/${surveyId}/${previousPage}`);
  };

  getSection = () => {
    return this.props.sections.find(
      (section) => section.id === this.props.question.section
    );
  };

  //toggle isChecked boolean
  optionClickHandler(id, score, selected) {
    console.debug("clicked an option", id, score, selected);
    this.props.setOptionAnswer({
      answerOptionId: id,
      score: score,
      isSelected: !selected,
    });
  }

  render() {
    const {
      classes,
      survey,
      progress,
      question,
      answers,
      buttonLabel,
      loadingQuestion,
      error,
      match,
      submission
    } = this.props;
    const { languageCode: surveyLanguage } = survey.settings;

    let content = null;

    //if question received
    if (question) {
      const options = question.answerOptions.map((option, index) => (
        <Option
          key={option.id}
          click={() =>
            this.optionClickHandler(
              option.id,
              option.score,
              answers[index].isSelected
            )
          }
          hoverText={option.hoverText}
          optionText={option.optionText}
          exampleText={option.exampleText}
          score={option.score}
          //populate this based on asnwers default -> false
          isChecked={answers[index].isSelected}
        />
      ));

      content = (
        <>
          {/* Attach ref div to the top of the page to server as an anchor to scroll to top on next Question click*/}
          <div ref={this.scrollToTopRef} />
          <Slide>
            <SectionHeader
              progress={progress}
              sections={survey.sections}
              surveyLanguage={surveyLanguage}
            />
            <Typography
              variant="h2"
              className={classes.questionContentTypography}
            >
              {console.debug(
                "These are the available question props..",
                question
              )}
              {question.questionContent}
            </Typography>

            {options}

            <Navigation
              prevHandler={this.navigateToPreviousPage}
              nextHandler={this.submitAnswerHandler}
              buttonLabel={buttonLabel}
              progress={progress}
              surveyLanguage={surveyLanguage}
              submissionId={submission.id}
            />
          </Slide>
          <Footer surveyLanguage={surveyLanguage} />
        </>
      );
    }

    if (loadingQuestion) {
      content = <Loader />;
    }

    if (error) {
      content = <ErrorHandler error={error} />;
    }

    //fallback if user is in this page but has no submission
    if (submission === null) {
      content = <Redirect to={`/survey/${match.params.id}`} />;
    }

    return <React.Fragment>{content}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    question: state.question,
    loadingQuestion: state.loadingQuestion,
    answers: state.currentQuestionState.answers,
    questionSubmission: state.currentQuestionState.questionSubmission,
    buttonLabel: state.buttonLabel,
    survey: state.survey,
    sections: state.survey.sections,
    progress: state.progress,
    userData: state.userData,
    submission: state.submission,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentBackgroundImage: (currentBackgroundImage) =>
      dispatch(actionTypes.setCurrentBackgroundImage(currentBackgroundImage)),
    getQuestion: (section, question) => {
      dispatch(actionTypes.getQuestion(section, question));
    },
    setOptionAnswer: (answer) => {
      dispatch(actionTypes.setOptionAnswer(answer));
    },
    setTextAnswer: (value) => {
      dispatch(actionTypes.setTextAnswer(value));
    },
    setError: (error) => dispatch(actionTypes.setError(error)),
    updateSubmissionData: (data) =>
      dispatch(actionTypes.updateSubmissionData(data)),
    updateAnswerOptionSubmissions: (
      questionSubmissionId,
      answerOptionSubmissions
    ) => {
      dispatch(
        actionTypes.updateAnswerOptionSubmissions(
          questionSubmissionId,
          answerOptionSubmissions
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuestionSlide));
