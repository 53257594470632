import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withStyles, Typography } from "@material-ui/core";
import Slide from "../../../components/Slides/Slide";
import ResultDetails from "./ResultDetails";
import ResultOverview from "./ResultOverview";
import * as actionTypes from "../../../store/actions";
import Footer from "../../../components/UI/Footer/Footer";
import BookAnAssessmentDialog from "../../../components/UI/Dialog/BookAnAssessmentDialog";

const styles = (theme) => ({
  buttons: {
    borderRadius: "1.4rem",
    minWidth: "100px",
    minHeight: "40px",
    backgroundColor: theme.palette.primary.main,
  },
  footerButton: {
    margin: theme.spacing(3, 0, 5),
  },
  header: {
    color: theme.palette.primary.main,
    "& p": {
      display: "inline-block",
    },
  },
  border: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    margin: `${theme.spacing(0.5)}px 0px`,
  },
  footer: {
    backgroundColor: theme.palette.secondary.main,
  },
  footerHeading: {
    padding: theme.spacing(2.5, 0),
  },
  footerBody: {
    padding: theme.spacing(1, 0),
  },
});

const summaryText = {
  NO: 'Oppsummering',
  EN: 'Summary',
}

class ResultSlide extends Component {
  constructor(props) {
    super(props);
    this.state = { isReady: false, resultsBySection: [] };
  }

  componentDidMount() {
    console.debug("ResultSlide mounted");
    const { id: surveyId, submissionId } = this.props.match.params;
    this.props.fetchSurveyIfNeeded(surveyId);
    this.props.fetchSubmissionIfNeeded(submissionId);

    // Set state to ready if submission & survey are already defined.
    if (this.props.submission && this.props.survey) {
      this.setState({
        isReady: true,
        resultsBySection: this.generateResults(),
      });
    }
  }

  componentDidUpdate() {
    if (!this.props.isLoading && !this.state.isReady) {
      this.setState({
        isReady: true,
        resultsBySection: this.generateResults(),
      });
    }
  }

  getHighestPriorityAnswers = () => {
    let sectionOptions = {};
    for (let questionSubmission of this.props.submission.questionSubmissions) {
      const section = this.props.survey.sections.find(
        (section) => section.id === questionSubmission.sectionId
      );
      const question = section.questions.find(
        (question) => question.id === questionSubmission.questionId
      );

      // Set the default max answer option for a section.
      if (!sectionOptions[section.id]) {
        sectionOptions[section.id] = {
          priority: 0,
          priorityClass: "grey",
          recommendationShort: section.noAnswerText,
          recommendationLong: section.noAnswerText,
        };
      }

      let maxAnswerOption = sectionOptions[section.id];

      // Find the highest priority answer option for the question.
      for (let answerOptionSubmission of questionSubmission.answerOptionSubmissions) {
        if (answerOptionSubmission.selected) {
          const answerOption = question.answerOptions.find(
            (answerOption) =>
              answerOption.id === answerOptionSubmission.answerOption
          );
          if (answerOption.priority > maxAnswerOption.priority) {
            maxAnswerOption = answerOption;
          }
        }
      }

      sectionOptions[section.id] = maxAnswerOption;
    }
    return sectionOptions;
  };

  generateResults = () => {
    let model = [];
    const answers = this.getHighestPriorityAnswers();

    for (let section of this.props.survey.sections) {
      const answer = answers[section.id];
      model.push({
        name: section.name,
        sectionId: section.id,
        index: section.index,
        genericText: section.resultPageContent,
        color: answer.priorityClass,
        optionText: answer.optionText,
        shortRecommendation: answer.recommendationShort,
        longRecommendation: answer.recommendationLong,
      });
    }

    return model;
  };

  render() {
    if (!this.state.isReady) {
      return null;
    }
    const {
      classes,
      match: { params: { sectionId, submissionId } },
     } = this.props;
    const { languageCode: surveyLanguage } = this.props.survey.settings;

    const resultHeader = (
      <div className={classes.header}>
        <Typography variant="h4">{summaryText[surveyLanguage]}</Typography>
        <div className={classes.border} />
      </div>
    );

    //TODO Tried to redirect to lead form if submission is not found - Does NOT work
    let redirect = null;
    if (this.state.isReady && this.props.submission === null) {
      console.debug("Redirecting to lead form...");
      return <Redirect to={`/survey/${this.props.match.params.id}`} />;
    }

    return (
      <>
        <Slide>
          {resultHeader}
          {sectionId ? (
            <ResultDetails
              result={this.state.resultsBySection.find(
                (result) =>
                  result.sectionId === this.props.match.params.sectionId
              )}
              surveyLanguage={surveyLanguage}
            />
          ) : (
            <ResultOverview
              resultIntro={this.props.survey.settings.resultPageContent}
              resultsBySection={this.state.resultsBySection}
              surveyLanguage={surveyLanguage}
              companyName={this.props.submission.companyName}
            />
          )}
        </Slide>
        <Footer
          surveyLanguage={surveyLanguage}
          hasDialog
          >
          <BookAnAssessmentDialog
            submissionId={submissionId}
            surveyLanguage={surveyLanguage}
            />
        </Footer>
        {redirect}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    submission: state.submission,
    isLoading: state.loadingSubmission || state.loadingSurvey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubmissionIfNeeded: (submissionId) => {
      dispatch(actionTypes.fetchSubmissionIfNeeded(submissionId));
    },
    fetchSurveyIfNeeded: (surveyId) => {
      dispatch(actionTypes.fetchSurveyIfNeeded(surveyId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResultSlide));

ResultSlide.propTypes = {
  classes: PropTypes.object,
  survey: PropTypes.object,
  submission: PropTypes.object,
  isLoading: PropTypes.bool,
  fetchSubmissionIfNeeded: PropTypes.func,
  fetchSurveyIfNeeded: PropTypes.func,
};
