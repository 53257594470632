import React from 'react';

import styles from './ErrorHandler.module.css';

const errorHandler = (props) => {

    return(
        <div className={styles.ErrorHandler}>
            <h3> Error: {props.error.message ? props.error.message : 'Error'}</h3>
            <p>
                Please try again in a few minutes.<br/><br/> If the problem persists, please contact <a href="support@onsagers.no">support@onsagers.no</a>.
            </p>
        </div>
    );
}

export default errorHandler;