import React from "react";
import PropTypes from "prop-types";

export default function HTML(props) {
  return (
    <div
      className={props.class}
      dangerouslySetInnerHTML={{ __html: props.content }}
    ></div>
  );
}

HTML.propTypes = {
  content: PropTypes.string,
};
